import React, { useMemo } from 'react';
import { EditCommunity } from './editCommunity';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateCommunity } from '../hooks/useUpdateCommunity';
import { selectIsUserAdmin } from '../../../state/selectors/userSelector';
import { useAppSelector } from '../../../state';
import { selectCommunityData } from '../../../state/selectors/communitySelector';
import { useCommunityForm } from '../hooks/useCommunityForm';
import { createLink } from '../../../utils/helperFunctions';
import { useAuth } from '../../../state/hooks/useAuth';
import { analytics } from '../../../analytics';

interface Props {
  closeModal: () => void;
}

export const EditCommunityContainer: React.FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate();
  const { urlKey = '' } = useParams<{ urlKey?: string }>();

  const communityData = useAppSelector(selectCommunityData);
  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const { user } = useAuth();
  const isUserCreator = useMemo(() => {
    return user && communityData?.createdByUser?.id
      ? Number(user.id) === Number(communityData.createdByUser?.id)
      : false;
  }, [user, communityData?.createdByUser?.id]);

  const {
    communityName,
    description,
    selectedTopicTags,
    topicTags,
    onRemoveTopic,
    onFilterTopics,
    onAddTopic,
    setCommunityName,
    setDescription,
    errors,
    inputTopicsValue,
    setErrors,
    fetchMoreTopics,
    hasMoreTopicPage,
    isSubscribedToNews,
    setIsSubscribedToNews
  } = useCommunityForm(true);

  const updateCommunityHandler = useUpdateCommunity(
    (newUrlKey) => {
      if (newUrlKey && newUrlKey !== urlKey) {
        navigate(createLink(`/community/${newUrlKey}/papers`));
      }
      closeModal();
    },
    (error) => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        updateError: error
      }));
    }
  );

  const handleUpdate = () => {
    updateCommunityHandler({
      urlKey,
      communityName,
      description,
      selectedTopicTags: selectedTopicTags.map((tag) => tag.urlKey),
      isSubscribedToNews,
      isUserAdmin,
      isUserCreator
    });
    analytics.events.editCommunity(communityName);
  };

  return (
    <EditCommunity
      initialCommunityName={communityName}
      initialDescription={description}
      selectedTopicTags={selectedTopicTags}
      topicTags={topicTags}
      removeTopic={onRemoveTopic}
      filterTopics={onFilterTopics}
      addTopic={onAddTopic}
      handleUpdate={handleUpdate}
      errors={errors}
      inputTopicsValue={inputTopicsValue}
      setCommunityName={setCommunityName}
      setDescription={setDescription}
      isUserAdmin={isUserAdmin}
      isUserCreator={isUserCreator}
      fetchMoreTopics={fetchMoreTopics}
      hasMoreTopicPage={hasMoreTopicPage}
      setIsSubscribedToNews={setIsSubscribedToNews}
      isSubscribedToNews={isSubscribedToNews}
    />
  );
};
