import { useState, useEffect, useCallback, MouseEventHandler } from 'react';
import { useAppDispatch } from '../../../../state';
import { closeModal, openModal } from '../../../../state/actions/modalsActions';
import { ApiLoginUser } from '../../../../api/oipub-api';
import { useAuth } from '../../../../state/hooks/useAuth';

interface UseTopBarReturn {
  user: ApiLoginUser | null;
  showDropdown: boolean;
  isMenuVisible: boolean;
  handleToggleDropdown: () => void;
  handleHideDropdown: () => void;
  handleLogoutClick: () => void;
  handleOpenModal: (modalName: string) => void;
  handleCloseModal: (modalName: string) => void;
  handleMouseDown: MouseEventHandler<HTMLDivElement>;
  toggleVisibility: () => void;
}

export const useTopBar = (): UseTopBarReturn => {
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  // Handle user logout
  const handleLogoutClick = useCallback(() => {
    logout();
  }, [logout]);

  // Toggle the dropdown menu visibility
  const handleToggleDropdown = useCallback(() => {
    setShowDropdown((prev) => !prev);
  }, []);

  // Hide the dropdown menu
  const handleHideDropdown = useCallback(() => {
    setShowDropdown(false);
  }, []);

  // Open a modal
  const handleOpenModal = useCallback(
    (modalName: string) => {
      dispatch(openModal(modalName));
    },
    [dispatch]
  );

  // Close a modal
  const handleCloseModal = useCallback(
    (modalName: string) => {
      dispatch(closeModal(modalName));
    },
    [dispatch]
  );

  // Prevent default mouse down behavior
  const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();
    },
    []
  );

  // Toggle the hamburger menu visibility
  const toggleVisibility = useCallback(() => {
    setIsMenuVisible((prev) => !prev);
  }, []);

  // Handle clicks outside the dropdown and hamburger menu
  const handleDocumentClick = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement;
    // Logic to determine if the click is outside by checking class names or other attributes
    // For future , control class of toggleButton
    if (
      !target.closest('.dropdown-menu') &&
      !target.closest('.hamburger-menu') &&
      !target.closest('.modal-overlay') &&
      !target.closest('.menu-button')
    ) {
      setIsMenuVisible(false);
      setShowDropdown(false);
    }
  }, []);

  // Manage event listeners for clicks outside
  useEffect(() => {
    if (showDropdown || isMenuVisible) {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    // Cleanup on unmount
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [showDropdown, isMenuVisible, handleDocumentClick]);

  // Reset dropdown when user changes (logs in/out)
  useEffect(() => {
    setShowDropdown(false);
  }, [user]);

  return {
    user,
    showDropdown,
    isMenuVisible,
    handleToggleDropdown,
    handleHideDropdown,
    handleLogoutClick,
    handleOpenModal,
    handleCloseModal,
    handleMouseDown,
    toggleVisibility
  };
};
