import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { thousandSeparator } from '../../utils/numbers-formatter';
import { analytics } from '../../analytics';
import { ShareButton } from '../share';
import { CommentPost } from '../../svgs/CommentPost';
import { DatePost } from '../../svgs/DatePost';
import ArticleDiscussions from '../../svgs/ArticleDiscussions';
import { RenderHTML } from './render-html';
import { createLink } from '../../utils/helperFunctions';
import { IDiscussionsPage } from '../../types/discussions.type';
import { IPaperPreview } from '../../types/paper.type';
import { formatDate } from '../../utils/userPostHelper';
import { usePaperDiscussionPreviewData } from './hooks/usePaperDiscussionPreviewData';
import { findEllipsedAbstract } from './utils/findEllipsedAbstract';

export interface Props {
  discussion: IDiscussionsPage;
  paper: IPaperPreview;
  trackingLabel?: 'search' | 'feed' | 'topic';
  isDiscussion?: boolean;
  showThreeItems?: boolean;
}

export const DiscussionPaperPreview: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { discussion, paper, trackingLabel } = props;

  const handleTopicTagClick = useCallback(
    (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      urlKey: string | null | undefined
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (urlKey) {
        navigate(createLink(`/topic/${urlKey}`));
      }
    },
    [navigate]
  );

  const { renderedTopicsKeywords, fullUrl, numOfTopicsKeywords } =
    usePaperDiscussionPreviewData({
      paper,
      handleTopicTagClick
    });

  const handleOpenPaper = useCallback(() => {
    if (trackingLabel) {
      analytics.events.openPaper(trackingLabel);
    }
  }, [trackingLabel]);

  let url = createLink('/paper');
  if (paper?.id) {
    url += `/${paper.id}`;
  }

  const abstractPreview = findEllipsedAbstract(paper?.abstract || '');
  return (
    <div className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 block w-full max-w-3xl mx-auto transition-colors duration-200 border-b">
      <Link
        to={url}
        onClick={handleOpenPaper}
        className={`item w-full ${props.isDiscussion ? 'discussion-class' : ''}`}
      >
        {props.isDiscussion && (
          <div className="disc_item_icon">
            <ArticleDiscussions className="discussion_icon" />
          </div>
        )}

        <div className="itemTitle font-bold flex mb-3 cursor-pointer">
          <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight flex">
            <RenderHTML htmlContent={paper.title} />
          </div>
        </div>
        {abstractPreview && abstractPreview !== '' ? (
          <div className="itemContent color-itemContent text-itemContent mb-5">
            <RenderHTML htmlContent={abstractPreview} />
          </div>
        ) : (
          ''
        )}
        <hr />
        <div
          className={`flex justify-between mt-4 ${props.showThreeItems ? 'flex-col-reverse' : ''}`}
        >
          <div
            className={`itemActions flex justify-between items-center text-black ${props.showThreeItems ? 'itemActionsSmall' : ''}`}
          >
            {paper.published ? (
              <div className="itemActionDate">
                <div className="flex items-center">
                  <DatePost />
                  <span className="text-half ml-1">
                    {formatDate(paper.published)}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="itemActionComments">
              <div className="flex items-center">
                <CommentPost className="flex-shrink-0" />
                <span className="text-half w-full ml-1">
                  {discussion.numComments}
                </span>
              </div>
            </div>
            <div className="itemActionShare">
              <div className="flex items-center">
                <ShareButton url={fullUrl} />
              </div>
            </div>
          </div>
          {renderedTopicsKeywords.length !== 0 && (
            <div
              className={`authorLink font-roboto text-half ${props.showThreeItems ? 'mb-4' : ''}`}
            >
              {props.showThreeItems ? (
                <>
                  {renderedTopicsKeywords.slice(0, 2)}
                  {numOfTopicsKeywords > 2 && (
                    <span className="text-gray-400">
                      +{thousandSeparator(numOfTopicsKeywords - 2)} more
                    </span>
                  )}
                </>
              ) : (
                renderedTopicsKeywords
              )}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};
