import { FC, useState } from 'react';
import { AccordionItem } from '../../components/accordion';
import { ArrowDown } from '../../svgs';
import { IPaperCitations, IPaperReference } from '../../types/paper.type';
import { Detail } from './paper-meta-data';

interface PaperAccordionMetricsProps {
  citedBy?: IPaperCitations[] | null;
  cites?: IPaperReference[] | null;
  publicationHotScore?: number | null;
  pageRank?: number | null;
}
export const PaperAccordionMetrics: FC<PaperAccordionMetricsProps> = ({
  citedBy,
  cites,
  publicationHotScore,
  pageRank
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleToggle = () => {
    if (
      (citedBy && citedBy.length > 0) ||
      (cites && cites.length > 0) ||
      publicationHotScore ||
      pageRank
    ) {
      setIsExpanded((prev) => !prev);
    }
  };

  const formatNumber = (value: number | null | undefined) => {
    if (value === null || value === undefined) return '--';
    return value.toFixed(3);
  };

  const formatCount = (array: any[] | null | undefined) => {
    return array && array.length > 0
      ? array.length < 10
        ? `0${array.length}`
        : array.length
      : '--';
  };

  const disabled =
    (!citedBy || citedBy.length === 0) &&
    (!cites || cites.length === 0) &&
    !publicationHotScore &&
    !pageRank;

  return (
    <AccordionItem
      title="Metrics"
      isExpanded={isExpanded}
      className="papersMetaTooltip"
      onToggle={handleToggle}
      disabled={disabled}
      tooltipText={disabled ? 'No metrics available' : undefined}
      collapsedIcon={<ArrowDown className="w-4 h-4 transform rotate-0" />}
      expandedIcon={<ArrowDown className="w-4 h-4 transform rotate-180" />}
    >
      <div className="block mt-2">
        {disabled ? (
          <div className="text-gray-500">No metrics available.</div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            <div className="textInsideYellow">
              <Detail heading="Cited By" noMargin>
                {formatCount(citedBy)}
              </Detail>
            </div>
            <div className="textInsideYellow">
              <Detail heading="Cites" noMargin>
                {formatCount(cites)}
              </Detail>
            </div>
            <div className="textInsideYellow">
              <Detail heading="Hot Score" noMargin>
                {formatNumber(publicationHotScore)}
              </Detail>
            </div>
            <div className="textInsideYellow">
              <Detail heading="PageRank" noMargin>
                {formatNumber(pageRank)}
              </Detail>
            </div>
          </div>
        )}
      </div>
    </AccordionItem>
  );
};
