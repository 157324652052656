import React, { useCallback, useEffect, useState } from 'react';
import { ArrowDown, PlusCircle } from '../../../svgs';
import { useAppSelector } from '../../../state';
import { SearchFieldContainer } from '../../../containers/core/search-field-container';
import { Footer } from '../footer';
import { MenuBurgerButton } from '../../../svgs/MenuBurgerButton';
import { Flex } from '../../../atoms';
import { MainLogo, TopBarModals } from '..';
import { AuthButtons } from './auth';
import { NewsTicker } from '../../newsTicker';
import { PAPERS_DISCUSSIONS_RADIO_STATES } from '../../../routes/constants';
import { PapersTopicFilter } from '../../filter/papersTopicFilter';
import { useTopBar } from './hooks/useTopBar';
import { selectPapers } from '../../../state/selectors/papersSelector';
import { selectTopicPapersData } from '../../../state/selectors/filterSelector';
import { useIsSearchParamsEmpty } from '../../papersDiscussionRadio/hooks/useIsSearchParamsEmpty';
import { UserDropdown } from '../userDropdown';
import { useNavigate } from 'react-router-dom';

export interface Props {
  children: React.ReactNode;
}

export const TopicTopBar: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const papersDiscussionsRadioState = useAppSelector(
    (state) => state.tabs.papersDiscussionsRadioState
  );
  const papersCount = useAppSelector(selectPapers).count;
  const papersFilter = useAppSelector(selectTopicPapersData).count;
  const isSearchParamsEmpty = useIsSearchParamsEmpty();

  const {
    user,
    showDropdown,
    isMenuVisible,
    handleToggleDropdown,
    handleHideDropdown,
    handleLogoutClick,
    handleOpenModal,
    handleCloseModal,
    handleMouseDown,
    toggleVisibility
  } = useTopBar();

  const [pendingModal, setPendingModal] = useState<string | null>(null);

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const handleModalOpen = (action: 'createPost' | 'createCommunity') => {
    if (user) {
      handleOpenModal(action);
    } else {
      setPendingModal(action);
      handleOpenModalHash('signup');
    }
  };

  useEffect(() => {
    if (user && pendingModal) {
      handleOpenModal(pendingModal);
      setPendingModal(null);
    }
  }, [user, pendingModal, handleOpenModal]);

  const { children } = props;
  return (
    <div className="tablet:flex-no-wrap  flex-no-wrap w-full flex sticky top-0 z-50">
      <div
        className={`tablet:flex-no-wrap h-auto flex-col mr-0 top-bar-left flex justify-start h-full hamburger-menu ${
          isMenuVisible ? 'showMenu' : ''
        }`}
      >
        <div className="top-bar-border-bottom pt-4 pb-4 px-4">
          <MainLogo />
          <div className="relative z-10 flex  flex-grow ">
            {user ? (
              <div className="relative w-full pt-4">
                <button
                  className="ml-auto w-full flex justify-between items-center"
                  onClick={handleToggleDropdown}
                  onBlur={handleHideDropdown}
                >
                  <span className="mr-1 userName">{user.displayName}</span>
                  <span className="">
                    <ArrowDown className="opacity-50" />
                  </span>
                </button>
                <UserDropdown
                  visible={showDropdown}
                  onMouseDown={handleMouseDown}
                  onLogoutClick={handleLogoutClick}
                />
              </div>
            ) : (
              <AuthButtons />
            )}
          </div>
        </div>
        <div className="h-full flex flex-col justify-between">
          <div className="px-4 pt-4 ">
            <div>
              {['createPost', 'createCommunity'].map((action) => (
                <Flex key={action} direction="flex-row" className="mb-2 mr-3">
                  <button
                    onClick={() =>
                      handleModalOpen(
                        action as 'createPost' | 'createCommunity'
                      )
                    }
                    className="btnSmall text-white bg-gradient-to-r text-small 
                  from-primaryLight to-primaryDefault px-4 py-2 gap-1 rounded 
                  opacity-100 hover:opacity-50 font-medium focus:outline-none w-full text-center"
                  >
                    <div className="flex items-center justify-center">
                      <PlusCircle className="mr-1" />
                      <span className="font-medium uppercase">
                        {user
                          ? action === 'createPost'
                            ? 'Create a new post'
                            : 'Create a Community'
                          : action === 'createPost'
                            ? 'Create a new post'
                            : 'Create a Community'}
                      </span>
                    </div>
                  </button>
                </Flex>
              ))}
            </div>
            {papersDiscussionsRadioState ===
              PAPERS_DISCUSSIONS_RADIO_STATES.PAPERS &&
            ((papersCount && papersCount > 0) ||
              (papersFilter && papersFilter > 0) ||
              !isSearchParamsEmpty) ? (
              <PapersTopicFilter />
            ) : (
              ''
            )}
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
      </div>
      <div className="w-full relative min-h-screen bg-silv main-page h-full">
        <div>
          <NewsTicker />
        </div>
        <div className="flex justify-center pt-3 pb-3 tablet:px-4 px-2 bg-white">
          <SearchFieldContainer />
          <div className="menu-button">
            <button onClick={toggleVisibility}>
              <MenuBurgerButton />
            </button>
          </div>
        </div>
        <div className="tablet:mt-0 tabsContainer tablet:order-none relative z-10 flex justify-center flex-grow order-last w-full ">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
      <TopBarModals closeModal={handleCloseModal} />
    </div>
  );
};
