import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { IDiscussionsPaged } from '../../types/discussions.type';

export const getDiscussionsByTopicUrlKey = createAsyncThunk<
  IDiscussionsPaged,
  {
    page: number;
    pageSize: number;
    urlKey: string;
    sortMethod?: string;
    signal?: AbortSignal;
  },
  { rejectValue: string }
>(
  'get/getDiscussionsByTopicUrlKey',
  async (
    { page, pageSize, urlKey, sortMethod, signal },
    { rejectWithValue }
  ) => {
    const query = `pageNum=${page}&pageSize=${pageSize}&order=${sortMethod || ''}`;
    const url = `${env.apiUrl}/api/v1/topics/${urlKey}/discussions?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        let errorMessage = '';
        if (response.status >= 400 && response.status < 500) {
          const errorData = await response.json();
          errorMessage =
            errorData.message ||
            'Failed to fetch discussions for the topic URL key.';
        } else if (response.status >= 500) {
          errorMessage =
            'Sorry, currently we can’t process your request. Please try again later.';
        }
        return rejectWithValue(errorMessage);
      }

      const data: IDiscussionsPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch discussions for the topic URL key.'
      );
    }
  }
);

export const getDiscussionsByCommunityUrlKey = createAsyncThunk<
  IDiscussionsPaged,
  {
    page: number;
    pageSize: number;
    urlKey: string;
    sortMethod?: string;
    signal?: AbortSignal;
  },
  { rejectValue: string }
>(
  'get/getDiscussionsByCommunityUrlKey',
  async (
    { page, pageSize, urlKey, sortMethod, signal },
    { rejectWithValue }
  ) => {
    const query = `pageNum=${page}&pageSize=${pageSize}&order=${sortMethod || ''}`;
    const url = `${env.apiUrl}/api/v1/communities/${urlKey}/discussions?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        let errorMessage = '';
        if (response.status >= 400 && response.status < 500) {
          const errorData = await response.json();
          errorMessage =
            errorData.message ||
            'Failed to fetch discussions for the community URL key.';
        } else if (response.status >= 500) {
          errorMessage =
            'Sorry, currently we can’t process your request. Please try again later.';
        }
        return rejectWithValue(errorMessage);
      }

      const data: IDiscussionsPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch discussions for the community URL key.'
      );
    }
  }
);

export const getDiscussionsHome = createAsyncThunk<
  IDiscussionsPaged,
  {
    page: number;
    pageSize: number;
    sortMethod?: string;
    signal?: AbortSignal;
  },
  {
    rejectValue: string;
  }
>(
  'get/getDiscussionsHome',
  async ({ page, pageSize, sortMethod, signal }, { rejectWithValue }) => {
    const query = `pageNum=${page}&pageSize=${pageSize}&order=${sortMethod || ''}`;
    const url = `${env.apiUrl}/api/v1/discussions?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        let errorMessage = '';
        if (response.status >= 400 && response.status < 500) {
          const errorData = await response.json();
          errorMessage =
            errorData.message ||
            'Failed to fetch discussions for the community URL key.';
        } else if (response.status >= 500) {
          errorMessage =
            'Sorry, currently we can’t process your request. Please try again later.';
        }
        return rejectWithValue(errorMessage);
      }
      const data: IDiscussionsPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch home discussions.');
    }
  }
);
