import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiCommunityResponse } from '../types/community.type';
import { env } from '../../env';
import { sendProtectedRequest } from './sendProtectedRequest';
import { ICommunity } from '../../types/community.type';

interface GetCommunityArgs {
  urlKey: string;
}

export interface UpdateCommunityArgs {
  urlKey: string;
  data: {
    name: string;
    description: string;
    topics: string[];
  };
}

interface DeleteCommunityArgs {
  urlKey: string;
}

interface CreateCommunityArgs {
  name?: string;
  description?: string;
  topics?: string[];
  subscribeToNews: boolean;
}

export const getCommunity = createAsyncThunk(
  'get/getCommunity',
  async ({ urlKey }: GetCommunityArgs, { signal }) => {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/communities/${urlKey}`,
      {
        method: 'GET',
        signal
      }
    );

    if (!response.ok) {
      let errorMessage = '';
      if (response.status >= 400 && response.status < 500) {
        const errorData = await response.json();
        errorMessage = errorData.message || 'Failed to fetch community';
      } else if (response.status >= 500) {
        errorMessage =
          'Sorry, currently we can’t process your request. Please try again later.';
      }
      throw new Error(errorMessage);
    }

    const res = await response.json();

    return res as IApiCommunityResponse;
  }
);

export const updateCommunity = createAsyncThunk<
  ICommunity,
  UpdateCommunityArgs,
  { rejectValue: string }
>(
  'community/updateCommunity',
  async ({ urlKey, data }, { rejectWithValue }) => {
    try {
      const response = await sendProtectedRequest(
        `${env.apiUrl}/api/v1/communities/${urlKey}`,
        {
          method: 'PATCH',
          body: data
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.message ||
          'An unexpected error occurred. Please try again later.';
        throw new Error(errorMessage);
      }

      const res = await response.json();

      return res;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'An unexpected error occurred. Please try again later.'
      );
    }
  }
);

export const deleteCommunity = createAsyncThunk(
  'delete/deleteCommunity',
  async ({ urlKey }: DeleteCommunityArgs, {}) => {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/communities/${urlKey}`,
      {
        method: 'DELETE'
      }
    );

    if (!response.ok) {
      throw new Error('Failed to delete the community');
    }

    return { urlKey };
  }
);

export const createCommunity = createAsyncThunk<
  ICommunity,
  CreateCommunityArgs,
  { rejectValue: string }
>(
  'community/createCommunity',
  async (
    { name, description, topics, subscribeToNews },
    { rejectWithValue }
  ) => {
    try {
      const body = {
        name,
        description,
        topics,
        subscribeToNews
      };

      const response = await sendProtectedRequest(
        `${env.apiUrl}/api/v1/communities`,
        {
          method: 'POST',
          body: body
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.message ||
          'An unexpected error occurred. Please try again later.';
        throw new Error(errorMessage);
      }

      const data: IApiCommunityResponse = await response.json();

      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'An unexpected error occurred. Please try again later.'
      );
    }
  }
);

export const subscribeToNews = createAsyncThunk<void, GetCommunityArgs>(
  'post/subscribeToNews',
  async ({ urlKey }, { rejectWithValue }) => {
    try {
      const response = await sendProtectedRequest(
        `${env.apiUrl}/api/v1/communities/${urlKey}/subscriptions/news/users/me`,
        {
          method: 'POST'
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.message || 'Failed to subscribe to community news.';
        throw new Error(errorMessage);
      }
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'An unexpected error occurred. Please try again later.'
      );
    }
  }
);

export const unsubscribeFromNews = createAsyncThunk<void, GetCommunityArgs>(
  'delete/unsubscribeFromNews',
  async ({ urlKey }, { rejectWithValue }) => {
    try {
      const response = await sendProtectedRequest(
        `${env.apiUrl}/api/v1/communities/${urlKey}/subscriptions/news/users/me`,
        {
          method: 'DELETE'
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.message || 'Failed to unsubscribe from community news.';
        throw new Error(errorMessage);
      }
    } catch (error: any) {
      return rejectWithValue(
        error.message || 'An unexpected error occurred. Please try again later.'
      );
    }
  }
);
