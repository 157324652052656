import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getPapersByTopicUrlKey,
  getPapersByCommunityUrlKey,
  searchPapers,
  getPapersHome
} from '../actions/papersActions';
import { IPaperPaged } from '../../types/papers.type';
import { addVoteToPaper, removeVoteFromPaper } from '../actions/paperActions';

interface PapersState {
  papers: IPaperPaged;
  searchPapers: IPaperPaged;
  loading: boolean;
  error: string | null;
  searchLoading: boolean;
  searchError: string | null;
  requestAbortedForNew: boolean;
  papersHome: IPaperPaged;
  papersHomeLoading: boolean;
  papersHomeError: string | null;
}

const initialState: PapersState = {
  papers: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: []
  },
  searchPapers: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: []
  },
  loading: false,
  error: null,
  searchLoading: false,
  searchError: null,
  papersHome: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: []
  },
  papersHomeLoading: false,
  papersHomeError: null,
  requestAbortedForNew: false
};

// Helper function to update votes in multiple lists
const updateVotes = (
  lists: IPaperPaged[],
  paperId: string | number,
  totalScore: number,
  alreadyVoted: boolean
) => {
  lists.forEach((papers) => {
    if (!papers.page) return;

    papers.page = papers.page.map((paper) =>
      String(paper.id) === String(paperId)
        ? {
            ...paper,
            votes: { totalScore, alreadyVoted }
          }
        : paper
    );
  });
};

const papersSlice = createSlice({
  name: 'papers',
  initialState,
  reducers: {
    resetPapersCount(state) {
      state.papers.count = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPapersByTopicUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPapersByTopicUrlKey.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papers = action.payload;
          state.loading = false;
        }
      )
      .addCase(getPapersByTopicUrlKey.rejected, (state, action) => {
        const signal = action.meta.arg?.signal;
        if (signal?.aborted) return;

        state.error =
          (action.payload as string) ||
          'Failed to fetch papers by topic URL key.';
        state.loading = false;
      })
      .addCase(getPapersByCommunityUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPapersByCommunityUrlKey.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papers = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(getPapersByCommunityUrlKey.rejected, (state, action) => {
        const signal = action.meta.arg?.signal;
        if (signal?.aborted) return;

        state.error =
          (action.payload as string) ||
          'Failed to fetch papers by community URL key.';
        state.loading = false;
      });

    builder
      .addCase(searchPapers.pending, (state) => {
        state.searchLoading = true;
        state.searchError = null;
      })
      .addCase(
        searchPapers.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.searchPapers = action.payload;
          state.searchLoading = false;
        }
      )
      .addCase(searchPapers.rejected, (state, action) => {
        state.searchLoading = false;
        if (action.error.name !== 'AbortError' && !state.requestAbortedForNew) {
          state.searchError =
            (action.payload as string) || 'Failed to fetch topics/communities';
        }
      });

    builder
      .addCase(getPapersHome.pending, (state) => {
        state.papersHomeLoading = true;
        state.papersHomeError = null;
      })
      .addCase(
        getPapersHome.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papersHome = action.payload;
          state.papersHomeLoading = false;
        }
      )
      .addCase(getPapersHome.rejected, (state, action) => {
        const signal = action.meta.arg?.signal;
        if (signal?.aborted) return;

        state.papersHomeError =
          (action.payload as string) || 'Failed to fetch home papers.';
        state.papersHomeLoading = false;
      });

    // Handle voting updates inside the list
    builder.addCase(
      addVoteToPaper.fulfilled,
      (
        state,
        action: PayloadAction<{
          paperId: string | number;
          totalScore: number;
          alreadyVoted: boolean;
        }>
      ) => {
        updateVotes(
          [state.papers, state.papersHome, state.searchPapers],
          action.payload.paperId,
          action.payload.totalScore,
          action.payload.alreadyVoted
        );
      }
    );

    builder.addCase(
      removeVoteFromPaper.fulfilled,
      (
        state,
        action: PayloadAction<{
          paperId: string | number;
          totalScore: number;
          alreadyVoted: boolean;
        }>
      ) => {
        updateVotes(
          [state.papers, state.papersHome, state.searchPapers],
          action.payload.paperId,
          action.payload.totalScore,
          action.payload.alreadyVoted
        );
      }
    );

    builder.addCase(addVoteToPaper.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to vote on paper';
    });

    builder.addCase(removeVoteFromPaper.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to remove vote';
    });
  }
});

export const { resetPapersCount } = papersSlice.actions;
export const papersReducer = papersSlice.reducer;
