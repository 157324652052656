import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../state';
import { setPapersDiscussionsRadioState } from '../../../state/slices/tabsReducer';
import {
  resetCommunityPaperFilterData,
  resetTopicPaperFilterData
} from '../../../state/slices/filterPapersReducer';
import { createLink } from '../../../utils/helperFunctions';

interface UsePapersDiscussionsTabsProps {
  contentUrlKey: string;
  hidePapersTab?: boolean;
}

export function usePapersDiscussionsTabs({
  contentUrlKey,
  hidePapersTab
}: UsePapersDiscussionsTabsProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const pathState = location.pathname.split('/').at(-1);
  const radioState =
    pathState === 'discussions' || pathState === 'papers'
      ? pathState
      : 'papers';

  const setRouteState = useCallback(
    (newState: 'papers' | 'discussions') => {
      dispatch(setPapersDiscussionsRadioState(newState));
      dispatch(resetTopicPaperFilterData());
      dispatch(resetCommunityPaperFilterData());
      navigate(createLink(`/${contentUrlKey}/${newState}`));
    },
    [contentUrlKey, dispatch, navigate]
  );

  const handlePapersClick = () => {
    if (radioState !== 'papers') {
      setRouteState('papers');
    }
  };

  const handleDiscussionsClick = () => {
    if (radioState !== 'discussions') {
      setRouteState('discussions');
    }
  };

  // Redirect if hidePapersTab is true but URL is /papers
  // useEffect(() => {
  //   if (hidePapersTab && radioState === 'papers') {
  //     setRouteState('discussions');
  //   }
  // }, [hidePapersTab, radioState, setRouteState]);

  return {
    handlePapersClick,
    handleDiscussionsClick,
    radioState,
    hidePapersTab
  };
}
