import { FC, useState } from 'react';
import { AccordionItem } from '../../components/accordion';
import { ArrowDown } from '../../svgs';
import { Detail } from './paper-meta-data';

interface PaperAccordionJournalProps {
  volume?: string | null;
  issue?: string | null;
  publisher?: string | null;
}

export const PaperAccordionJournal: FC<PaperAccordionJournalProps> = ({
  volume,
  issue,
  publisher
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleToggle = () => {
    if (volume || issue || publisher) {
      setIsExpanded((prev) => !prev);
    }
  };

  const disabled = !volume && !issue && !publisher;

  return (
    <AccordionItem
      title="Journal Details"
      isExpanded={isExpanded}
      className="papersMetaTooltip"
      onToggle={handleToggle}
      disabled={disabled}
      tooltipText={disabled ? 'No journal details available' : undefined}
      collapsedIcon={<ArrowDown className="w-4 h-4 transform rotate-0" />}
      expandedIcon={<ArrowDown className="w-4 h-4 transform rotate-180" />}
    >
      <div className="block mt-2">
        {disabled ? (
          <div className="text-gray-500">No journal details available.</div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {volume && (
              <div className="textInsideYellow">
                <Detail heading="Volume" noMargin>
                  {volume}
                </Detail>
              </div>
            )}
            {issue && (
              <div className="textInsideYellow">
                <Detail heading="Issue" noMargin>
                  {issue}
                </Detail>
              </div>
            )}
            {publisher && (
              <div className="col-span-2">
                <div className="textInsideYellow">
                  <Detail heading="Publisher" noMargin>
                    {publisher}
                  </Detail>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </AccordionItem>
  );
};
