import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITopicSummaryPaged } from '../../types/topics.type';
import {
  getLandingTopicsData,
  getTopicsData,
  searchTopics
} from '../actions/topicsActions';
import { ICommonHubPaged } from '../../types/hubs.type';

interface TopicsState {
  topics: ITopicSummaryPaged | null;
  loading: boolean;
  error: string | null;
  searchResults: ICommonHubPaged | null;
  searchLoading: boolean;
  searchError: string | null;
  landingTopics: ITopicSummaryPaged | null;
  landingLoading: boolean;
  landingErrors: string | null;
  requestAbortedForNew: boolean;
  creationDefaultTopics: ITopicSummaryPaged | null;
  creationDefaultLoading: boolean;
  creationDefaultError: string | null;
  creationSearchTopics: ITopicSummaryPaged | null;
  creationSearchLoading: boolean;
  creationSearchError: string | null;
}

const initialState: TopicsState = {
  topics: null,
  loading: false,
  error: null,
  searchResults: null,
  searchLoading: false,
  searchError: null,
  landingTopics: null,
  landingLoading: false,
  landingErrors: null,
  requestAbortedForNew: false,
  creationDefaultTopics: null,
  creationDefaultLoading: false,
  creationDefaultError: null,
  creationSearchTopics: null,
  creationSearchLoading: false,
  creationSearchError: null
};

const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopicsData.pending, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationDefaultTopics = null;
          state.creationDefaultLoading = true;
          state.creationDefaultError = null;
        } else {
          state.loading = true;
          state.error = null;
        }
      })
      .addCase(getTopicsData.fulfilled, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationDefaultTopics = action.payload;
          state.creationDefaultLoading = false;
        } else {
          state.topics = action.payload;
          state.loading = false;
        }
      })
      .addCase(getTopicsData.rejected, (state, action) => {
        const { target } = action.meta.arg;
        const signal = action.meta.arg?.signal;
        if (signal?.aborted) return;

        if (target === 'creation') {
          state.creationDefaultError =
            (action.payload as string) || 'Failed to fetch topics';
          state.creationDefaultLoading = false;
        } else {
          state.error = (action.payload as string) || 'Failed to fetch topics';
          state.loading = false;
        }
      })
      .addCase(searchTopics.pending, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationSearchTopics = null;
          state.creationSearchLoading = true;
          // state.creationSearchError = null;
        } else {
          state.searchLoading = true;
          // state.searchError = null;
          state.searchResults = null;
        }
      })
      .addCase(searchTopics.fulfilled, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationSearchTopics = action.payload;
          state.creationSearchLoading = false;
          state.creationSearchError = null;
        } else {
          state.searchResults = action.payload;
          state.searchLoading = false;
          state.searchError = null;
        }
      })
      .addCase(searchTopics.rejected, (state, action) => {
        const { target } = action.meta.arg;
        if (target === 'creation') {
          state.creationSearchError =
            action.payload || 'Failed to search topics';
          state.creationSearchTopics = null;
          // state.creationDefaultTopics = null;
          state.creationSearchLoading = false;
        } else {
          state.searchLoading = false;
          if (
            action.error.name !== 'AbortError' &&
            !state.requestAbortedForNew
          ) {
            state.error = action.payload || 'Failed to fetch topics';
          }
        }
      })
      .addCase(getLandingTopicsData.pending, (state) => {
        state.landingLoading = true;
        state.landingErrors = null;
      })
      .addCase(
        getLandingTopicsData.fulfilled,
        (state, action: PayloadAction<ITopicSummaryPaged>) => {
          state.landingTopics = action.payload;
          state.landingLoading = false;
        }
      )
      .addCase(getLandingTopicsData.rejected, (state, action) => {
        state.landingErrors = action.error.message || 'Failed to fetch topics';
        state.landingLoading = false;
      });
  }
});

export const topicsReducer = topicsSlice.reducer;
