import { SortingButtonsContainer } from '../../components/sortingButtons';
import {
  setTopicSortMethod,
  useAppDispatch,
  useAppSelector
} from '../../state';
import {
  TOPIC_SORT_METHOD_DISPLAY_NAMES,
  TopicSortMethod
} from '../../utils/sortingConst';

import TopicsList from './topics-list';

export const TopicsListContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const topicSortMethod = useAppSelector((state) => state.sort.topicSortMethod);
  const handleSortingTopicChange = (method: TopicSortMethod) => {
    dispatch(setTopicSortMethod(method));
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <SortingButtonsContainer
          sortMethods={TOPIC_SORT_METHOD_DISPLAY_NAMES}
          currentSortMethod={topicSortMethod}
          onSortMethodChange={handleSortingTopicChange}
        />
        <TopicsList />
      </div>
    </div>
  );
};
