import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getCommunitiesData } from '../../../state/actions/communitiesActions';
import {
  selectCommunities,
  selectCommunitiesError,
  selectCommunitiesLoading
} from '../../../state/selectors/communitiesSelector';

interface UseGetCommunitiesData {
  page: number;
  pageSize: number;
  communityType: number;
  sortMethod?: string;
}

export const useGetCommunitiesData = ({
  page,
  pageSize,
  communityType,
  sortMethod
}: UseGetCommunitiesData) => {
  const dispatch = useAppDispatch();
  const communities = useAppSelector(selectCommunities);
  const loading = useAppSelector(selectCommunitiesLoading);
  const error = useAppSelector(selectCommunitiesError);
  useEffect(() => {
    const controller = new AbortController();

    const fetchCommunities = async () => {
      await dispatch(
        getCommunitiesData({
          page,
          pageSize,
          communityType,
          sortMethod,
          signal: controller.signal
        })
      );
    };

    fetchCommunities();
    return () => {
      controller.abort();
    };
  }, [dispatch, page, pageSize, communityType, sortMethod]);

  return { communities, loading, error };
};
