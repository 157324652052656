import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ITopicSummary } from '../../types/topic.type';

interface GetTopicsArgs {
  urlKey: string;
}

export const getTopic = createAsyncThunk(
  'get/getTopic',
  async ({ urlKey }: GetTopicsArgs, { rejectWithValue }) => {
    try {
      const response = await fetch(`${env.apiUrl}/api/v1/topics/${urlKey}`, {
        method: 'GET'
      });

      if (!response.ok) {
        let errorMessage = '';
        if (response.status >= 400 && response.status < 500) {
          const errorData = await response.json();
          errorMessage = errorData.message || 'Failed to fetch topic';
        } else if (response.status >= 500) {
          errorMessage =
            'Sorry, currently we can’t process your request. Please try again later.';
        }
        throw new Error(errorMessage);
      }

      const res = await response.json();
      return res as ITopicSummary;
    } catch (error) {
      return rejectWithValue('Failed to fetch topic');
    }
  }
);
