import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IComment, ICommentsResponse } from '../../types/comment.type';
import {
  addVoteToComment,
  deleteComment,
  editCommentPapers,
  editCommentUserPost,
  getComments,
  postCommentPaper,
  postCommentUserPost,
  removeVoteFromComment
} from '../actions/commentsActions';

interface CommentsState {
  comments: ICommentsResponse | null;
  loading: boolean;
  error: string | null;
  newCommentFormShow: boolean;
}

const initialState: CommentsState = {
  comments: null,
  loading: false,
  error: null,
  newCommentFormShow: false
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    clearComments: (state) => {
      state.comments = null;
      state.loading = false;
      state.error = null;
    },
    // Close all reply and edit forms for every comment
    closeAllComments: (state) => {
      if (state.comments && state.comments.comments) {
        const closeComments = (comments: IComment[]) => {
          comments.forEach((comment) => {
            comment.isReplyOpen = false;
            comment.isEditOpen = false;
            if (comment.comments) {
              closeComments(comment.comments);
            }
          });
        };
        closeComments(state.comments.comments);
      }
    },
    // Open the reply form for a specific comment by ID
    openReplyForComment: (state, action: PayloadAction<number>) => {
      const targetCommentId = action.payload;

      const openReply = (comments: IComment[]) => {
        for (const comment of comments) {
          if (comment.id === targetCommentId) {
            comment.isReplyOpen = true;
            comment.isEditOpen = false;
            return;
          }
          if (comment.comments) {
            openReply(comment.comments);
          }
        }
      };

      if (state.comments && state.comments.comments) {
        openReply(state.comments.comments);
      }
    },
    // Open the edit form for a specific comment by ID
    openEditForComment: (state, action: PayloadAction<number>) => {
      const targetCommentId = action.payload;

      const openEdit = (comments: IComment[]) => {
        for (const comment of comments) {
          if (comment.id === targetCommentId) {
            comment.isEditOpen = true;
            comment.isReplyOpen = false;
            return;
          }
          if (comment.comments) {
            openEdit(comment.comments);
          }
        }
      };

      if (state.comments && state.comments.comments) {
        openEdit(state.comments.comments);
      }
    },
    // Update the reply text for a specific comment by ID
    storeReplyText: (
      state,
      action: PayloadAction<{ commentId: number; text: string }>
    ) => {
      const { commentId, text } = action.payload;

      const updateReplyText = (comments: IComment[]) => {
        comments.forEach((comment) => {
          if (comment.id === commentId) {
            comment.replyText = text;
          }
          if (comment.comments) {
            updateReplyText(comment.comments);
          }
        });
      };

      if (state.comments && state.comments.comments) {
        updateReplyText(state.comments.comments);
      }
    },
    // Set the visibility of the new comment form
    setNewCommentFormVisibility: (state, action: PayloadAction<boolean>) => {
      state.newCommentFormShow = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // getComments
      .addCase(getComments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getComments.fulfilled,
        (state, action: PayloadAction<ICommentsResponse>) => {
          state.comments = action.payload;
          state.loading = false;
        }
      )
      .addCase(getComments.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch comments';
        state.loading = false;
      })
      // Handle state for posting a comment (Paper)
      .addCase(postCommentPaper.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postCommentPaper.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postCommentPaper.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to post comment';
        state.loading = false;
      })
      // Handle  state for posting a comment (User Post)
      .addCase(postCommentUserPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postCommentUserPost.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postCommentUserPost.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to post comment';
        state.loading = false;
      })
      // Handle pending state for editing a comment editCommentPapers
      .addCase(editCommentPapers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCommentPapers.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editCommentPapers.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to edit comment';
        state.loading = false;
      })
      // editCommentUserPost
      .addCase(editCommentUserPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCommentUserPost.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editCommentUserPost.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to edit comment';
        state.loading = false;
      })
      // Handle state for deleting a comment
      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteComment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete comment';
        state.loading = false;
      })
      .addCase(
        addVoteToComment.fulfilled,
        (
          state,
          action: PayloadAction<{
            commentId: number;
            totalScore: number;
            alreadyVoted: boolean;
          }>
        ) => {
          const updateVote = (comments: IComment[]) => {
            for (const comment of comments) {
              if (comment.id === action.payload.commentId) {
                comment.votes = {
                  totalScore: action.payload.totalScore,
                  alreadyVoted: action.payload.alreadyVoted
                };
                return;
              }
              if (comment.comments) {
                updateVote(comment.comments);
              }
            }
          };
          if (state.comments?.comments) {
            updateVote(state.comments.comments);
          }
        }
      )
      .addCase(
        removeVoteFromComment.fulfilled,
        (
          state,
          action: PayloadAction<{
            commentId: number;
            totalScore: number;
            alreadyVoted: boolean;
          }>
        ) => {
          const updateVote = (comments: IComment[]) => {
            for (const comment of comments) {
              if (comment.id === action.payload.commentId) {
                comment.votes = {
                  totalScore: action.payload.totalScore,
                  alreadyVoted: action.payload.alreadyVoted
                };
                return;
              }
              if (comment.comments) {
                updateVote(comment.comments);
              }
            }
          };
          if (state.comments?.comments) {
            updateVote(state.comments.comments);
          }
        }
      );
  }
});

export const {
  clearComments,
  closeAllComments,
  setNewCommentFormVisibility,
  openReplyForComment,
  openEditForComment,
  storeReplyText
} = commentsSlice.actions;
export default commentsSlice.reducer;
