// Finding matches in the abstract
export const findMatchesInAbstract = (text: string): string => {
  const regex =
    /[^.!?]*<span\s+[^>]*class=["'][^"']*\bhighlight\b[^"']*["'][^>]*>.*?<\/span>[^.!?]*[.!?]/g;
  const matches = text.match(regex);

  if (!matches) return '';

  const uniqueSentences = Array.from(
    new Set(matches.map((sentence) => sentence.trim()))
  );

  if (uniqueSentences.length === 0) return '';

  const firstSentenceMatch = text.match(/[^.!?]*[.!?]/);
  const firstSentence = firstSentenceMatch ? firstSentenceMatch[0].trim() : '';

  const hasFirstSentenceMatch = uniqueSentences.some((sentence) =>
    sentence.startsWith(firstSentence)
  );

  let previewText = hasFirstSentenceMatch ? '' : '... ';

  let lastEnd = 0;

  uniqueSentences.forEach((sentence, index) => {
    if (index > 0) {
      const currentStart = text.indexOf(sentence, lastEnd);
      if (currentStart === -1) {
        return;
      }

      const interveningText = text.slice(lastEnd, currentStart).trim();

      if (/^\s*$/.test(interveningText)) {
        previewText += '<br/>';
      } else {
        if (previewText.endsWith('.')) {
          previewText = previewText.slice(0, -1);
        }
        previewText += ' ... ';
      }
    }

    previewText += sentence;
    lastEnd = text.indexOf(sentence, lastEnd) + sentence.length;
  });
  previewText = previewText.trim();
  if (previewText.endsWith('.')) {
    previewText += '..';
  } else {
    previewText += '...';
  }

  return previewText;
};
