import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { startup } from './startup';
import Hotjar from '@hotjar/browser';
import './tailwind.output.css';
import { analytics } from './analytics';
// Initialize Google Tag Manager
analytics.initGTM();
// Init HotJar statistics gathering
if (!/localhost|test/.test(window.location.hostname)) {
  const siteId = 5189253;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

const container = document.getElementById('root');
const root = createRoot(container);
startup();
root.render(<App tab="home" />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
