import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Field,
  Input,
  Label,
  RequiredFieldLabel,
  Validation
} from '../../components/form';
import { QuestionMark, VisibilityHidden, VisibilityShow } from '../../svgs';
import { Tooltip } from '../../components/core/tooltip';
import { useAuth } from '../../state/hooks/useAuth';
import { Loading } from '../../components/core';
import { texts } from '../../text';

interface Props {
  closeModal: () => void;
}

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  agreeToTerms: false,
  showPassword: false
};

type FormData = typeof initialValues;

export const passwordValidationSchema = Yup.string()
  .required('Required')
  .min(8, 'Password must be at least 8 characters long')
  .max(64, 'Password must not exceed 64 characters')
  .matches(/[A-Z]/, 'Password must have at least one uppercase letter')
  .matches(/[a-z]/, 'Password must have at least one lowercase letter')
  .matches(/[0-9]/, 'Password must contain at least one number')
  .matches(
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
    'Password must have at least one special character'
  );

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email'
    )
    .required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  password: passwordValidationSchema,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .when('showPassword', {
      is: false,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired()
    }),
  agreeToTerms: Yup.boolean().test(
    'agree',
    'You must agree to the terms and conditions.',
    (b) => !!b
  )
});

export const Register: React.FC<Props> = (props) => {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableSubmitShowLoading, setDisableSubmitShowLoading] =
    useState(false);

  const [registerError, setRegisterError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const openLoginModal = useCallback(
    () => handleOpenModalHash('login'),
    [handleOpenModalHash]
  );

  const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
  };

  async function handleSubmit(values: FormData) {
    setDisableSubmit(true);
    setDisableSubmitShowLoading(true);
    try {
      await register({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password
      });
      props.closeModal();
    } catch (err: any) {
      handleRegisterError(err);
    }
    setDisableSubmitShowLoading(false);
  }

  const handleRegisterError = useCallback(
    (error: any) => {
      setRegisterError(error);
    },
    [setRegisterError]
  );

  const formik = useFormik<FormData>({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  // Reset disableForgotSubmit when email or password changes
  useEffect(() => {
    setRegisterError('');
    setDisableSubmit(false);
    setDisableSubmitShowLoading(false);
  }, [formik.values.email, formik.values.password]);

  const togglePasswordVisibility = () => {
    const newShowPassword = !showPassword;
    setShowPassword(newShowPassword);
    formik.setFieldValue('showPassword', newShowPassword);
  };

  const passwordRequirements = (
    <div className="password-requirements">
      Password must:
      <ul>
        <li>Be 8-64 characters long</li>
        <li>Have at least one uppercase letter (A-Z)</li>
        <li>Have at least one lowercase letter (a-z)</li>
        <li>Contain at least one number (0-9)</li>
        <li>
          Have at least one special character (e.g., !@#$%^&*()_+-=[]{}
          |;&apos;:&quot;,.&lt;&gt;?)
        </li>
      </ul>
    </div>
  );

  return (
    <div className="">
      <div className="text-sm">{texts.signupDescription}</div>
      <form
        className="w-full max-w-md mx-auto mt-8 register-form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className="input-single tablet:pr-2">
          <Field className="">
            <Label htmlFor="email">
              <RequiredFieldLabel label={'Email Address'} />
            </Label>
            <Input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={`${
                formik.touched.email && formik.errors.email
                  ? 'border-error-500'
                  : ''
              } ${formik.touched.email && !formik.errors.email ? 'border-green-500' : ''}`}
              placeholder="ex. email@email.com"
            />
            {formik.errors.email && formik.touched.email && (
              <Validation message={formik.errors.email} />
            )}
          </Field>
        </div>
        <div className="flex-col tablet:flex tablet:flex-row">
          <Field className="tablet:mr-2">
            <Label htmlFor="firstName">
              <RequiredFieldLabel label={'Name'} />
            </Label>
            <Input
              id="firstName"
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              className={`${
                formik.touched.firstName && formik.errors.firstName
                  ? 'border-error-500'
                  : ''
              } ${formik.touched.firstName && !formik.errors.firstName ? 'border-green-500' : ''}`}
              placeholder="ex. John"
            />
            {formik.errors.firstName && formik.touched.firstName && (
              <Validation message={formik.errors.firstName} />
            )}
          </Field>
          <Field className="tablet:ml-2">
            <Label htmlFor="lastName">
              <RequiredFieldLabel label={'Surname'} />
            </Label>
            <Input
              id="lastName"
              name="lastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              className={`${
                formik.touched.lastName && formik.errors.lastName
                  ? 'border-error-500'
                  : ''
              } ${formik.touched.lastName && !formik.errors.lastName ? 'border-green-500' : ''}`}
              placeholder="ex. Smith"
            />
            {formik.errors.lastName && formik.touched.lastName && (
              <Validation message={formik.errors.lastName} />
            )}
          </Field>
        </div>
        <div
          className={`flex-col tablet:flex tablet:flex-row ${showPassword ? 'input-single' : ''}  `}
        >
          <Field className="tablet:mr-2">
            <div className="flex justify-between">
              <Label htmlFor="password">
                <RequiredFieldLabel label={'Password'} />
              </Label>
              <Tooltip className="tooltipPassword" text={passwordRequirements}>
                <QuestionMark />
              </Tooltip>
            </div>
            <div className="relative">
              <Input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                onBlur={handlePasswordBlur}
                value={formik.values.password}
                className={`pr-5 ${
                  formik.touched.password && formik.errors.password
                    ? 'border-error-500'
                    : ''
                } ${formik.touched.password && !formik.errors.password ? 'border-green-500' : ''}`}
                placeholder="********"
              />
              <button
                type="button"
                className="absolute right-0 showHideBtn"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <VisibilityShow /> : <VisibilityHidden />}
              </button>
            </div>
            {formik.errors.password && formik.touched.password && (
              <Validation message={formik.errors.password} />
            )}
          </Field>
          {!showPassword && (
            <Field className="tablet:ml-2">
              <Label htmlFor="confirmPassword">
                <RequiredFieldLabel label={'Confirm Password'} />
              </Label>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                className={`${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? 'border-error-500'
                    : ''
                } ${formik.touched.confirmPassword && !formik.errors.confirmPassword ? 'border-green-500' : ''}`}
                placeholder="********"
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <Validation message={formik.errors.confirmPassword} />
                )}
            </Field>
          )}
        </div>
        <div className="mb-8">
          <Checkbox
            label={
              <>
                I agree to the{' '}
                <Link
                  className="hover:text-primary-500 transition-colors duration-200 cursor-pointer font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/terms-and-conditions"
                >
                  terms and conditions
                </Link>
                .
              </>
            }
            name="agreeToTerms"
            id="agreeToTerms"
            onChange={formik.handleChange}
            checked={formik.values.agreeToTerms}
          />
          {formik.errors.agreeToTerms && formik.touched.agreeToTerms && (
            <Validation message={formik.errors.agreeToTerms} />
          )}
        </div>
        {registerError && (
          <div className="text-primary-500 mb-4 text-center ">
            {registerError}
          </div>
        )}
        <div className="flex justify-center">
          <Button
            type="submit"
            className="btnSmall px-8 py-2"
            disabled={disableSubmit}
          >
            {disableSubmitShowLoading ? (
              <>
                Sign Up <Loading className="loadingConfirm" />
              </>
            ) : (
              'Sign Up'
            )}
          </Button>
        </div>
        <p className="mt-8 text-sm text-center">
          Already have an account?{' '}
          <button
            className="text-primary-500 font-semibold"
            onClick={openLoginModal}
            type="button"
          >
            Sign in here
          </button>
        </p>
      </form>
    </div>
  );
};
