import {
  CommunitySortMethod,
  DiscussionHomeSortMethod,
  DiscussionSortMethod,
  PaperCommunitySortMethod,
  PaperHomeSortMethod,
  PaperTopicSortMethod,
  TOGGLE_SORT_METHODS,
  TopicSortMethod,
  UserPostsSortMethod
} from '../../utils/sortingConst';
import { ViewModeToggle } from '../viewModeToggle';
import { SortingButton } from './sortingButton';

export type GenericSortMethod =
  | DiscussionSortMethod
  | PaperCommunitySortMethod
  | PaperTopicSortMethod
  | TopicSortMethod
  | CommunitySortMethod
  | UserPostsSortMethod
  | PaperHomeSortMethod
  | DiscussionHomeSortMethod;

export interface SortingButtonsContainerProps<T> {
  sortMethods: Record<string, { label: string; tooltip?: string }>;
  currentSortMethod: T;
  onSortMethodChange: (method: T) => void;
}

export const SortingButtonsContainer = <T extends string>({
  sortMethods,
  currentSortMethod,
  onSortMethodChange
}: SortingButtonsContainerProps<T>) => {
  return (
    <div className="w-full px-4">
      <div className="max-w-3xl-plusrem flex w-full ml-auto mr-auto">
        <div className="flex flex-row justify-between laptop:justify-between desktop:justify-between tablet:justify-between pt-5 w-full ">
          <div className="flex flex-wrap justify-center tablet:justify-start laptop:justify-start w-full btns_container">
            {Object.entries(sortMethods).map(([key, { tooltip }]) => {
              const togglePair = Object.values(TOGGLE_SORT_METHODS).find(
                ([first, second]) => key === first || key === second
              );
              if (togglePair && key === togglePair[0]) {
                return (
                  <SortingButton
                    key={togglePair.join('_')}
                    methodValue={togglePair[0] as T}
                    currentSortMethod={currentSortMethod}
                    onSortMethodChange={onSortMethodChange}
                    displayNames={sortMethods}
                    tooltip={tooltip}
                    toggleValues={togglePair as [T, T]}
                  />
                );
              }
              if (!togglePair) {
                return (
                  <SortingButton
                    key={key}
                    methodValue={key as T}
                    currentSortMethod={currentSortMethod}
                    onSortMethodChange={onSortMethodChange}
                    displayNames={sortMethods}
                    tooltip={tooltip}
                  />
                );
              }
              return null;
            })}
          </div>
          <ViewModeToggle />
        </div>
      </div>
    </div>
  );
};
