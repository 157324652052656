import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getDeletedPostsByCommunity,
  getDeletedPostsByTopic,
  getUserPostsByCommunityUrlKey,
  getUserPostsByTopicUrlKey
} from '../actions/userPostsActions';
import { IUserPostPaged } from '../../types/userPosts.type';
import {
  addVoteToUserPost,
  removeVoteFromUserPost
} from '../actions/userPostActions';

interface UserPostsState {
  posts: IUserPostPaged;
  deletedPosts: IUserPostPaged;
  loading: boolean;
  loadingDeleted: boolean;
  error: string | null;
  errorDeleted: string | null;
}

const updateUserPostVotes = (
  posts: IUserPostPaged,
  postId: number,
  totalScore: number,
  alreadyVoted: boolean
) => {
  if (!posts.page) return;
  posts.page = posts.page.map((post) =>
    post.id === postId
      ? {
          ...post,
          votes: {
            totalScore,
            alreadyVoted
          }
        }
      : post
  );
};

const initialState: UserPostsState = {
  posts: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: null
  },
  deletedPosts: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: null
  },
  loading: false,
  loadingDeleted: false,
  error: null,
  errorDeleted: null
};

const userPostsSlice = createSlice({
  name: 'userPosts',
  initialState,
  reducers: {
    clearDeletedPosts(state) {
      state.deletedPosts = initialState.deletedPosts;
    }
  },
  extraReducers: (builder) => {
    // Get Deleted By Community
    builder
      .addCase(getDeletedPostsByCommunity.pending, (state) => {
        state.loadingDeleted = true;
        state.errorDeleted = null;
      })
      .addCase(
        getDeletedPostsByCommunity.fulfilled,
        (state, action: PayloadAction<IUserPostPaged>) => {
          state.deletedPosts = action.payload;
          state.loadingDeleted = false;
        }
      )
      .addCase(getDeletedPostsByCommunity.rejected, (state, action) => {
        state.errorDeleted =
          action.error.message ||
          'Failed to fetch deleted posts for the community.';
        state.loadingDeleted = false;
      });

    // Get Deleted By Topics
    builder
      .addCase(getDeletedPostsByTopic.pending, (state) => {
        state.loadingDeleted = true;
        state.errorDeleted = null;
      })
      .addCase(
        getDeletedPostsByTopic.fulfilled,
        (state, action: PayloadAction<IUserPostPaged>) => {
          state.deletedPosts = action.payload;
          state.loadingDeleted = false;
        }
      )
      .addCase(getDeletedPostsByTopic.rejected, (state, action) => {
        state.errorDeleted =
          action.error.message ||
          'Failed to fetch deleted posts for the topic.';
        state.loadingDeleted = false;
      });

    // Get User Posts By Topic URL Key
    builder
      .addCase(getUserPostsByTopicUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUserPostsByTopicUrlKey.fulfilled,
        (state, action: PayloadAction<IUserPostPaged>) => {
          state.posts = action.payload;
          state.loading = false;
        }
      )
      .addCase(getUserPostsByTopicUrlKey.rejected, (state, action) => {
        state.error =
          action.error.message ||
          'Failed to fetch user posts for the topic URL key.';
        state.loading = false;
      });

    // Get User Posts By Community URL Key
    builder
      .addCase(getUserPostsByCommunityUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUserPostsByCommunityUrlKey.fulfilled,
        (state, action: PayloadAction<IUserPostPaged>) => {
          state.posts = action.payload;
          state.loading = false;
        }
      )
      .addCase(getUserPostsByCommunityUrlKey.rejected, (state, action) => {
        state.error =
          action.error.message ||
          'Failed to fetch user posts for the community URL key.';
        state.loading = false;
      });

    builder
      .addCase(addVoteToUserPost.fulfilled, (state, action) => {
        updateUserPostVotes(
          state.posts,
          action.payload.userPostId,
          action.payload.totalScore,
          action.payload.alreadyVoted
        );
      })
      .addCase(removeVoteFromUserPost.fulfilled, (state, action) => {
        updateUserPostVotes(
          state.posts,
          action.payload.userPostId,
          action.payload.totalScore,
          action.payload.alreadyVoted
        );
      });
  }
});

export const { clearDeletedPosts } = userPostsSlice.actions;
export const userPostsReducer = userPostsSlice.reducer;
