import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import { IComment } from '../../../types/comment.type';
import { getComments } from '../../../state/actions/commentsActions';
import { useAuth } from '../../../state/hooks/useAuth';
export const useGetCommentsbyIdData = (
  discussionId: number | null | undefined
) => {
  const dispatch = useAppDispatch();
  const [commentsData, setCommentsData] = useState<IComment[] | null>(null);
  const commentsSortMethod = useAppSelector(
    (state) => state.sort.commentSortMethod
  );
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (discussionId) {
        try {
          const comments = await dispatch(
            getComments({ discussionId, order: commentsSortMethod })
          ).unwrap();
          setCommentsData(comments.comments);
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch comments:', error);
        }
      } else {
        setCommentsData(null);
      }
    };

    fetchData();
  }, [dispatch, discussionId, commentsSortMethod, user]);

  return { commentsData };
};
