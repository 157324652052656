import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectPapers,
  selectPapersLoading,
  selectPapersError
} from '../../../state/selectors/papersSelector';
import { getPapersByTopicUrlKey } from '../../../state/actions/papersActions';
import {
  resetCommunityPaperFilterData,
  resetTopicPaperFilterData
} from '../../../state/slices/filterPapersReducer';
import { useAuth } from '../../../state/hooks/useAuth';

interface UseGetPapersByTopicUrlKeyData {
  page: number;
  pageSize: number;
  urlKey: string;
  sortMethod?: string;
}

export const useGetPapersByTopicUrlKeyData = ({
  page,
  pageSize,
  urlKey,
  sortMethod
}: UseGetPapersByTopicUrlKeyData) => {
  const dispatch = useAppDispatch();
  const papers = useAppSelector(selectPapers);
  const loading = useAppSelector(selectPapersLoading);
  const error = useAppSelector(selectPapersError);
  const { user } = useAuth();

  useEffect(() => {
    const controller = new AbortController();

    dispatch(resetTopicPaperFilterData());
    dispatch(resetCommunityPaperFilterData());
    const fetchPapers = async () => {
      await dispatch(
        getPapersByTopicUrlKey({
          page,
          pageSize,
          urlKey,
          sortMethod,
          signal: controller.signal
        })
      );
    };

    fetchPapers();

    return () => {
      controller.abort();
    };
  }, [dispatch, page, pageSize, urlKey, sortMethod, user]);

  return { papers, loading, error };
};
