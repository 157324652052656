import { findMatchesInAbstract } from './findMatchesInAbstract';

export function findEllipsedAbstract(abstract: string): string {
  if (!abstract) return '';
  const firstSentenceMatch =
    abstract.match(/^(.*?[.!?])(\s|$)/) || abstract.match(/(.*?[.!?])\s/);
  // If no match is found, fallback to the whole abstract.
  const firstSentence = firstSentenceMatch
    ? firstSentenceMatch[1].trim()
    : abstract.trim();

  // Get matches using the existing function.
  const matches = findMatchesInAbstract(abstract).trim();

  // Use matches if they exist (non-empty), otherwise use the first sentence.
  const baseContent = matches !== '' ? matches : firstSentence;

  // Only append ellipsis if the original abstract (trimmed) is longer than the base content.
  const trimmedAbstract = abstract.trim();
  const ellipsedAbstract =
    baseContent.length < trimmedAbstract.length
      ? baseContent.endsWith('.')
        ? `${baseContent}..`
        : `${baseContent}...`
      : baseContent;

  return ellipsedAbstract;
}
