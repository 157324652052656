import TagManager from 'react-gtm-module';

const GTM_ID = process.env.REACT_APP_GTM_ID;

type GTMEvent = {
  event: string;
  category: string;
  label?: string;
  value?: number;
};

function initGTM() {
  if (GTM_ID) {
    TagManager.initialize({ gtmId: GTM_ID });
  } else {
    // eslint-disable-next-line no-console
    console.log('GTM_ID is not defined');
  }
}

function page(path: string) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'page_view',
      page_location: window.location.href,
      page_path: path,
      page_title: document.title
    }
  });
}

function trackEvent({ event, category, label, value }: GTMEvent) {
  TagManager.dataLayer({
    dataLayer: {
      event,
      category,
      label,
      value
    }
  });
}

function setUserId(userId?: string) {
  TagManager.dataLayer({
    dataLayer: {
      userId
    }
  });
}

const events = {
  search(label: string) {
    trackEvent({ event: 'search', category: 'engagement', label });
  },
  nextPage() {
    trackEvent({ event: 'next_search_page', category: 'engagement' });
  },
  openPaper(label: 'search' | 'feed' | 'topic') {
    trackEvent({ event: 'open_paper', category: 'engagement', label });
  },
  login() {
    trackEvent({ event: 'login', category: 'registration' });
  },
  register() {
    trackEvent({ event: 'sign_up', category: 'registration' });
  },
  loginCta(label: 'join_discussion') {
    trackEvent({ event: 'login_cta', category: 'CTA', label });
  },
  expandAbstract() {
    trackEvent({ event: 'expand_abstract', category: 'engagement' });
  },
  addComment(label: 'paper' | 'topic' | 'post') {
    trackEvent({ event: 'comment', category: 'engagement', label });
  },
  clickPaperLink() {
    trackEvent({ event: 'paper_link', category: 'outbound_link' });
  },
  openReferral() {
    trackEvent({ event: 'open_referral', category: 'registration' });
  },
  sendReferral() {
    trackEvent({ event: 'send_referral', category: 'registration' });
  },
  //New events
  newPost(title: string) {
    trackEvent({ event: 'new_post', category: 'content', label: title });
  },
  newCommunity(name: string) {
    trackEvent({ event: 'new_community', category: 'content', label: name });
  },
  subscribe() {
    trackEvent({
      event: 'subscribe',
      category: 'engagement',
      label: 'subscribe_action'
    });
  },
  unsubscribe() {
    trackEvent({
      event: 'unsubscribe',
      category: 'engagement',
      label: 'unsubscribe_action'
    });
  },
  editPost(title: string) {
    trackEvent({ event: 'edit_post', category: 'content', label: title });
  },
  editCommunity(name: string) {
    trackEvent({ event: 'edit_community', category: 'content', label: name });
  },
  editComment(label: 'paper' | 'topic' | 'post') {
    trackEvent({ event: 'comment', category: 'engagement', label });
  }
};

export const analytics = {
  initGTM,
  page,
  setUserId,
  events
};
