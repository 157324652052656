import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../state';
import { getUserPost } from '../../../state/actions/userPostActions';
import {
  clearUserPost,
  setUserPost
} from '../../../state/slices/userPostReducer';
import { useAuth } from '../../../state/hooks/useAuth';

export function useGetUserPost() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();
  const postFromState = location.state?.post;
  const { user } = useAuth();

  useEffect(() => {
    if (postFromState) {
      dispatch(setUserPost(postFromState));
    } else if (id) {
      const promise = dispatch(getUserPost({ id }));
      promise.unwrap().catch((error) => {
        if (error.name !== 'AbortError') {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch the user post:', error);
        }
      });
    }

    return () => {
      dispatch(clearUserPost());
    };
  }, [dispatch, id, postFromState, user]);
}
