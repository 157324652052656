import { FC } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useAppSelector } from '../../state';
import { Loading } from '../core/loading';
import { ErrorComponent } from '../core/error';
import { SearchPagination } from '../../routes/search/search-pagination';
import { Previewv2 } from '../paper/paper-preview';
import { useGetPapersHomeData } from './hooks/useGetPapersHomeData';
import { selectHomePaperFilterData } from '../../state/selectors/filterSelector';
import { useQueryParams } from '../../hooks/useQueryParams';
import { IPaper } from '../../types/paper.type';

interface PapersHomeListProps {
  currentPaperPage?: number;
  isTripleView: boolean;
  updatePage: (newPage: number) => void;
}

export const PapersHomeList: FC<PapersHomeListProps> = ({
  currentPaperPage,
  isTripleView,
  updatePage
}) => {
  const paperHomeSortMethod = useAppSelector(
    (state) => state.sort.paperHomeSortMethod
  );
  const queryParams = useQueryParams();

  const isFilterActive = Boolean(
    queryParams.title ||
      queryParams.author ||
      queryParams.journal ||
      queryParams.publishedDateFrom ||
      queryParams.publishedDateTo
  );

  const page = useAppSelector(
    (state) => selectHomePaperFilterData(state).pageNum
  );

  const pageUsingNow = isFilterActive ? page : currentPaperPage;

  const { papers, loading, error } = useGetPapersHomeData({
    page: pageUsingNow!,
    pageSize: 10,
    sortMethod: paperHomeSortMethod
  });

  if (loading) {
    return <Loading className="mt-10" />;
  }

  if (error) {
    return <ErrorComponent error={error} />;
  }

  if (papers && papers.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>No papers found for the applied filters.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4 mt-4">
      <div className="flex w-full max-w-3xl">
        <div className="flex flex-wrap w-full">
          {papers?.page && papers.page.length > 0 && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                600: isTripleView ? 2 : 1,
                900: isTripleView ? 3 : 1
              }}
              className="w-full"
            >
              <Masonry gutter="14px">
                {papers.page.map((paper: IPaper, idx: number) => (
                  <Previewv2
                    key={`HomeList-${idx}`}
                    paper={paper}
                    showThreeItems={isTripleView}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      </div>

      {papers?.numPages && pageUsingNow && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={pageUsingNow}
            lastPage={papers.numPages}
            numOfMobilPages={5}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};
