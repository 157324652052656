import { Tooltip } from '../../components/core/tooltip';
import { useCommentCount } from '../../components/discussion';
import { VoteComponent } from '../../components/paper/paperPreviewVotes';
import { RenderHTML } from '../../components/paper/render-html';
import { ShareButton } from '../../components/share';
import { useAppSelector } from '../../state';
import { selectComments } from '../../state/selectors/commentsSelector';
import { CommentPost } from '../../svgs/CommentPost';
import { DiamondIcon } from '../../svgs/DiamondIcon';
import { FireIcon } from '../../svgs/FireIcon';
import { LinkGo } from '../../svgs/LinkGo';
import { createLink } from '../../utils/helperFunctions';

export interface Props {
  doiLink?: string;
}

export const Furniturev2: React.FC<Props> = (props: Props) => {
  const { doiLink } = props;
  const paper = useAppSelector((state) => state.paper.paper);

  const currentUrl = window.location.origin;
  const postLink = createLink(`/posts/${paper?.id}`);
  const comments = useAppSelector(selectComments)?.comments;
  const commentCount = useCommentCount(comments);
  const handleGoToLink = () => {
    if (doiLink) {
      window.open(doiLink, '_blank');
    }
  };

  return (
    <div className="mb-3">
      <div className="items-baseline">
        <div className="flex mb-3 justify-between items-center">
          <h1 className=" text-xl font-bold leading-snug">
            <RenderHTML htmlContent={paper?.title} />
          </h1>
          <Tooltip
            className="paperTitleTooltip px-2"
            text={`Go to the source at ${doiLink}`}
          >
            <button onClick={handleGoToLink}>
              <LinkGo />
            </button>
          </Tooltip>
        </div>
        <div className="flex items-center flex-wrap">
          <div className="text-primary-500 text-sm flex mr-1">
            #Pa[{paper?.id}]
          </div>
          <div
            className={`itemActions itemActionsPapers flex ${paper?.publicationHotScore && paper?.pageRank ? 'justify-between' : 'justify-start'} items-center text-black`}
          >
            <VoteComponent
              labelStyle="text-sm"
              id={paper!.id!}
              votes={paper!.votes}
              type={'paper'}
            />
            {paper?.publicationHotScore && (
              <div className="itemActionComments">
                <div className="flex items-center">
                  <Tooltip
                    className="tooltipComments tooltipCitations ml-1 mr-1"
                    text={`Publication Hot Score: ${paper.publicationHotScore}`}
                  >
                    <div className="flex items-center">
                      <FireIcon />
                      <span className="text-sm ml-1">
                        {paper.publicationHotScore.toFixed(3)}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
            {paper?.pageRank && (
              <div className="itemActionComments">
                <div className="flex items-center">
                  <Tooltip
                    className="tooltipComments tooltipCitations ml-1 mr-1"
                    text={`Publication Top Score: ${paper.pageRank}`}
                  >
                    <div className="flex items-center">
                      <DiamondIcon />
                      <span className="text-sm ml-1">
                        {paper.pageRank.toFixed(3)}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
            <div className="itemActionComments">
              <div className="flex items-center ml-1 mr-1">
                <CommentPost className="flex-shrink-0" />
                <span className="text-sm w-full ml-1">{commentCount || 0}</span>
              </div>
            </div>
            <div className="itemActionShare">
              <div className="flex items-center">
                <ShareButton textBig={true} url={currentUrl + postLink} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
