import React, { useEffect, useState } from 'react';
import { TopicSummaryV3 } from '../topicsAndCommunities/topic-summary';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SearchPagination } from '../search/search-pagination';
import { useAppSelector } from '../../state';
import qs from 'qs';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loading } from '../../components/core';
import { useGetTopicsData } from '../topic-v2/hooks/useGetTopicsData';
import { ErrorComponent } from '../../components/core/error';
import { useQueryParams } from '../../hooks/useQueryParams';
import { selectGeneralViewMode } from '../../state/selectors/viewModeSelector';
import { ITopicSummary } from '../../types/topic.type';

interface TopicsContentProps {}

export const TopicsList: React.FC<TopicsContentProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const topicSortMethod = useAppSelector((state) => state.sort.topicSortMethod);
  const queryParams = useQueryParams();
  const initialPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
  const [currentTopicPage, setCurrentTopicPage] = useState(initialPage);
  const { topics, loading, error } = useGetTopicsData({
    page: currentTopicPage,
    pageSize: 10,
    sortMethod: topicSortMethod
  });
  const viewMode = useAppSelector(selectGeneralViewMode);
  const isTripleView = viewMode === 'triple';

  const updatePage = (newPage: number) => {
    setCurrentTopicPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  useEffect(() => {
    const newPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
    setCurrentTopicPage(newPage);
  }, [location.search, queryParams]);

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  if (topics && topics.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>No topics yet.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex flex-col items-center h-full w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <div className="flex flex-wrap w-full w-full-for-all">
            {topics && topics.page && topics.page.length > 0 && (
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  350: 1,
                  600: isTripleView ? 2 : 1,
                  900: isTripleView ? 3 : 1
                }}
              >
                <Masonry gutter="14px">
                  {topics.page.map(
                    (
                      topic: ITopicSummary,
                      idx: React.Key | null | undefined
                    ) => (
                      <TopicSummaryV3 key={idx} topic={topic} />
                    )
                  )}
                </Masonry>
              </ResponsiveMasonry>
            )}
          </div>
        </div>
      </div>
      {topics && topics.numPages && currentTopicPage && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={currentTopicPage}
            lastPage={topics.numPages}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};

export default TopicsList;
