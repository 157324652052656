import { useAppDispatch, useAppSelector } from '../../state';
import { setViewMode } from '../../state/actions/viewmodeActions';
import { selectGeneralViewMode } from '../../state/selectors/viewModeSelector';
import { ViewLine } from '../../svgs/ViewLine';
import { ViewThreeInLine } from '../../svgs/ViewThreeInLine';

export const ViewModeToggle = () => {
  const dispatch = useAppDispatch();
  const isTripleView = useAppSelector(selectGeneralViewMode) === 'triple';

  const handleSetViewMode = (mode: 'single' | 'triple') => {
    dispatch(setViewMode(mode));
  };

  return (
    <div className="flex justify-center pt-0 mb-2 pl-1">
      <div className="flex justify-end max-w-3xl">
        <div className="flex justify-end btnsOrder">
          <button
            onClick={() => handleSetViewMode('single')}
            className={!isTripleView ? 'active-class' : ''}
          >
            <ViewLine />
          </button>
          <button
            onClick={() => handleSetViewMode('triple')}
            className={isTripleView ? 'active-class' : ''}
          >
            <ViewThreeInLine />
          </button>
        </div>
      </div>
    </div>
  );
};
