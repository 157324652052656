import { FC, useState } from 'react';
import { PapersList } from './papers-list';
import { DiscussionsList } from './discussions-list';
import { useAppSelector } from '../../state';
import { CommunityTitlePlace } from '../../routes/community/communityTitlePlace';
import { selectCommunityData } from '../../state/selectors/communitySelector';
import { PapersDiscussionsTabs } from './papersDiscussionsTabs';
import { DeletedUserPostsListCommunity } from '../userPosts';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import { AdminSidebar } from '../adminSidebar';
import { useLocation, useParams } from 'react-router-dom';
import { Loading } from '../core';

export const PapersDiscussionsTabsCommunity: FC = () => {
  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const [showDeletedPosts, setShowDeletedPosts] = useState(false);
  const communityData = useAppSelector(selectCommunityData);
  const { urlKey } = useParams();
  const location = useLocation();

  const pathState = location.pathname.split('/').at(-1);
  const radioState =
    pathState === 'discussions' || pathState === 'papers'
      ? pathState
      : 'papers';
  return (
    <div className="h-full w-full">
      <div className="flex flex-col h-full w-full">
        <PapersDiscussionsTabs
          contentUrlKey={`community/${communityData?.urlKey || urlKey}`}
          hidePapersTab={communityData?.numPapers === 0}
          type={'community'}
          numPapers={communityData?.numPapers}
          numDiscussions={communityData?.numDiscussions}
        />
        <div className="maincont flex flex-row w-full">
          <div className="pagepart w-full h-full">
            <CommunityTitlePlace />
            <div className="flex w-full flex-row h-full">
              <div className="flex w-full flex-col">
                {isUserAdmin && showDeletedPosts ? (
                  <DeletedUserPostsListCommunity />
                ) : radioState === 'papers' ? (
                  <PapersList isCommunity={true} />
                ) : radioState === 'discussions' ? (
                  <DiscussionsList isCommunity={true} />
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
          {isUserAdmin && (
            <AdminSidebar
              showDeletedPosts={showDeletedPosts}
              setShowDeletedPosts={setShowDeletedPosts}
            />
          )}
        </div>
      </div>
    </div>
  );
};
