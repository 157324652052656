import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  getPaperById,
  getPaperBySlug
} from '../../../state/actions/paperActions';
import { useAppDispatch } from '../../../state';
import { clearPaper } from '../../../state/slices/paperReducer';
import { useAuth } from '../../../state/hooks/useAuth';

export function useGetPaper() {
  const dispatch = useAppDispatch();
  const { id, yearMonth, slug, hash } = useParams<{
    id?: string;
    yearMonth?: string;
    slug?: string;
    hash?: string;
  }>();

  const { user } = useAuth();

  useEffect(() => {
    if (id) {
      dispatch(getPaperById(Number(id)))
        .unwrap()
        .catch((error) => {
          if (error.name !== 'AbortError') {
            // eslint-disable-next-line no-console
            console.error('Failed to fetch paper by ID:', error);
          }
        });
    } else if (yearMonth && slug && hash) {
      dispatch(
        getPaperBySlug({
          yearMonth: Number(yearMonth),
          slug,
          hash: Number(hash)
        })
      )
        .unwrap()
        .catch((error) => {
          if (error.name !== 'AbortError') {
            // eslint-disable-next-line no-console
            console.error('Failed to fetch paper by slug:', error);
          }
        });
    }
    // Cleanup function to clear paper data when component unmounts
    return () => {
      dispatch(clearPaper());
    };
  }, [dispatch, id, yearMonth, slug, hash, user]);
}
