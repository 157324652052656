import React, { useCallback, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import { IComment } from '../../types/comment.type';
import { DiscussionType } from './discussion-type';
import { useAppSelector, useAppDispatch } from '../../state';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import { analytics } from '../../analytics';
import { PlusCircleOrange } from '../../svgs/PlusCircleOrange';
import { MinusCircle } from '../../svgs/MinusCircle';
import { Tooltip } from '../core/tooltip';
import { StaticTimeAgo } from '../staticTimeAgo';
import { RichTextEditor } from '../richTextEditor';
import { Button } from '../form';
import { ConfirmationModalWrapper } from '../modals';
import {
  closeAllComments,
  storeReplyText,
  openReplyForComment,
  openEditForComment,
  setNewCommentFormVisibility
} from '../../state/slices/commentsReducer';
import { useAuth } from '../../state/hooks/useAuth';
import { selectUserPost } from '../../state/selectors/userPostSelector';
import { selectPaper } from '../../state/selectors/paperSelector';
import { useNotifications } from '../notificationProvider/hooks/useNotifications';
import { getErrorMessage } from '../../utils/helperFunctions';
import { VoteComponent } from '../paper/paperPreviewVotes';

export interface Props {
  comment: IComment;
  onReply(text: string, parentId: number): Promise<void>;
  onEdit: (id: number, commentId: number, newText: string) => Promise<void>;
  onDelete: (commentId: number) => Promise<void>;
  type: DiscussionType;
}

export const Comment: React.FC<Props> = ({
  comment,
  onReply,
  onEdit,
  onDelete,
  type
}) => {
  const dispatch = useAppDispatch();
  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const { user, isAuthenticated: isLoggedIn } = useAuth();
  const isUserCreator = useMemo(() => {
    return user && comment.createdByUser?.id
      ? Number(user.id) === Number(comment.createdByUser?.id)
      : false;
  }, [user, comment.createdByUser?.id]);
  const [collapsed, setCollapsed] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [editText, setEditText] = useState(comment.text || '');
  const [replyText, setReplyText] = useState(comment.replyText || '');
  const { notify } = useNotifications();
  const replyEditorRef = useRef<ReactQuill | null>(null);
  const editEditorRef = useRef<ReactQuill | null>(null);

  const post = useAppSelector(selectUserPost);
  const paper = useAppSelector(selectPaper);

  const id = type === 'post' ? post?.id : paper?.id;

  const handleDeleteClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (comment.id) {
        await onDelete(comment.id);
        notify('success', 'Comment deleted successfully.');
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      notify('error', `Failed to delete comment: ${getErrorMessage(error)}`);
    }
  };
  const toggleCollapsed = () => {
    setCollapsed((c) => !c);
  };

  const replyFormik = useFormik({
    initialValues: {
      replyText: replyText || ''
    },
    validationSchema: Yup.object({
      replyText: Yup.string()
        .required('Required')
        .min(1, 'Comment must be at least 1 character long')
    }),
    onSubmit: async () => {
      if (comment.id) {
        setSaving(true);
        try {
          analytics.events.addComment(type);
          await onReply(replyText, comment.id);
          dispatch(closeAllComments());
        } finally {
          setSaving(false);
        }
      }
    },
    validateOnChange: true,
    validateOnBlur: true
  });

  const editFormik = useFormik({
    initialValues: {
      editText: comment.text || ''
    },
    validationSchema: Yup.object({
      editText: Yup.string()
        .required('Required')
        .min(1, 'Comment must be at least 1 character long')
    }),
    onSubmit: async () => {
      if (comment.id) {
        setSaving(true);
        try {
          await onEdit(Number(id), comment.id, editText);
          analytics.events.editComment(type);
          dispatch(closeAllComments());
        } finally {
          setSaving(false);
        }
      }
    },
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleEditChange = useCallback(
    (value: string) => {
      setEditText(value);
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');
      const textContent = doc.body.textContent || '';
      editFormik.setFieldValue('editText', textContent);
    },
    [editFormik, setEditText]
  );

  const handleReplyChange = useCallback(
    (value: string) => {
      if (comment.id) {
        setReplyText(value);
        const parser = new DOMParser();
        const doc = parser.parseFromString(value, 'text/html');
        const textContent = doc.body.textContent || '';
        dispatch(storeReplyText({ commentId: comment.id, text: replyText }));
        replyFormik.setFieldValue('replyText', textContent);
      }
    },
    [dispatch, replyFormik, replyText, comment.id]
  );

  const handleReplyClick = () => {
    if (comment.id) {
      dispatch(setNewCommentFormVisibility(false));
      dispatch(closeAllComments());
      dispatch(openReplyForComment(comment.id));
      setTimeout(() => {
        const editor = replyEditorRef.current?.getEditor();
        if (editor) {
          editor.focus();
          const textLength = comment.replyText?.length || 0;
          editor.setSelection(textLength, textLength);
        }
      }, 0);
    }
  };

  const handleEditClick = () => {
    if (comment.id) {
      dispatch(setNewCommentFormVisibility(false));
      dispatch(closeAllComments());
      dispatch(openEditForComment(comment.id));
      setTimeout(() => {
        const editor = editEditorRef.current?.getEditor();
        if (editor) {
          editor.focus();
          const textLength = comment.text?.length || 0;
          editor.setSelection(textLength, textLength);
        }
      }, 0);
    }
  };

  const handleCancelReply = () => {
    if (comment.id) {
      dispatch(closeAllComments());
      setReplyText('');
    }
  };

  const handleCancelEdit = () => {
    if (comment.id) {
      dispatch(closeAllComments());
      setEditText(comment.text || '');
    }
  };

  return (
    <div className="relative max-w-2xl mb-6">
      <div
        className="hover:bg-primary-500 -left-5 absolute top-0 w-1 h-full transition-all duration-200 bg-gray-400 cursor-pointer"
        onClick={toggleCollapsed}
      />
      <div className="flex flex-wrap items-center mb-2 text-xs">
        {collapsed ? (
          <div
            className="text-primary-500 hover:text-primary-700 w-5 h-5 mr-2 transition-colors duration-200 cursor-pointer select-none"
            onClick={() => setCollapsed(false)}
            title="Expand comment"
          >
            <PlusCircleOrange />
          </div>
        ) : (
          <div
            className="text-primary-500 hover:text-primary-700 w-5 h-5 mr-2 transition-colors duration-200 cursor-pointer select-none"
            onClick={() => setCollapsed(true)}
            title="Collapse comment"
          >
            <MinusCircle />
          </div>
        )}
        <h5 className="mr-2 font-bold">
          {comment?.createdByUser?.displayName}
        </h5>
        <Tooltip
          className="tooltipComments"
          text={
            `Created: ${moment
              .utc(comment.createdUtc)
              .local()
              .format('DD/MM/YYYY HH:mm')}` +
            (comment.deletedUtc
              ? `\nDeleted: ${moment
                  .utc(comment.deletedUtc)
                  .local()
                  .format('DD/MM/YYYY HH:mm')}`
              : comment.lastModifiedUtc
                ? `\nUpdated: ${moment
                    .utc(comment.lastModifiedUtc)
                    .local()
                    .format('DD/MM/YYYY HH:mm')}`
                : '')
          }
        >
          <span className="opacity-50">
            <StaticTimeAgo
              date={moment.utc(comment.createdUtc).local().toDate()}
            />
          </span>
          {(comment.deletedUtc && comment.deletedByUser) ||
          comment.lastModifiedUtc ? (
            <span className="opacity-50">
              &nbsp;•&nbsp;
              {comment.deletedUtc && comment.deletedByUser ? (
                <>
                  Deleted :&nbsp;
                  <StaticTimeAgo
                    date={moment.utc(comment.deletedUtc).local().toDate()}
                  />
                </>
              ) : comment.lastModifiedUtc ? (
                <>
                  Edited :&nbsp;
                  <StaticTimeAgo
                    date={moment.utc(comment.lastModifiedUtc).local().toDate()}
                  />
                </>
              ) : null}
            </span>
          ) : null}
        </Tooltip>
        {comment.comments && comment.comments.length > 0 && collapsed && (
          <div className="text-gray-600 w-full">
            <span>{comment.comments.length} replies</span>
          </div>
        )}
      </div>
      {!collapsed && (
        <div className="flex flex-col">
          <form onSubmit={editFormik.handleSubmit}>
            {!comment.isEditOpen && (
              <div className="mb-2 text-sm leading-relaxed">
                {comment.state === 2 ? (
                  `This comment was deleted by ${
                    comment?.deletedByUser?.id !== comment?.createdByUser?.id
                      ? 'moderator'
                      : 'user'
                  }`
                ) : (
                  <div className="ql-snow">
                    <div
                      className="ql-editor ql-editor--show whitespace-pre-line"
                      dangerouslySetInnerHTML={{ __html: comment.text || '' }}
                    ></div>
                  </div>
                )}
              </div>
            )}
            {comment.isEditOpen && (
              <div className="">
                <div className="commentEditor mb-4">
                  <RichTextEditor
                    ref={editEditorRef}
                    value={editText}
                    setValue={handleEditChange}
                    maxLength={10000}
                    disabled={saving}
                    error={
                      editFormik.touched.editText && editFormik.errors.editText
                        ? editFormik.errors.editText
                        : undefined
                    }
                    touched={editFormik.touched.editText}
                    placeholder="Edit your comment"
                  />
                </div>
                <div className="flex justify-start items-start laptop:flex-row w-full">
                  <Button
                    type="button"
                    outline={true}
                    disabled={saving}
                    onClick={handleCancelEdit}
                    className="py-2 tablet:px-12 px-6"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={saving}
                    type="submit"
                    className="py-2 tablet:px-12 px-6 ml-2"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
            {comment.state !== 2 && (
              <div className="flex">
                <div className="mr-2">
                  <VoteComponent
                    id={comment.id!}
                    type="comment"
                    votes={comment.votes}
                  />
                </div>

                {!comment.isEditOpen && isLoggedIn && (
                  <div className="flex items-center text-xs font-bold text-gray-600">
                    <span
                      className="last:mr-0 hover:text-primary-500 mr-2 transition-colors duration-200 cursor-pointer select-none"
                      onClick={handleReplyClick}
                    >
                      Reply
                    </span>
                    {(isUserCreator || isUserAdmin) && (
                      <>
                        <span
                          className="last:mr-0 hover:text-primary-500 mr-2 transition-colors duration-200 cursor-pointer select-none"
                          onClick={handleEditClick}
                        >
                          Edit
                        </span>
                        <span
                          className="last:mr-0 hover:text-primary-500 mr-2 transition-colors duration-200 cursor-pointer select-none"
                          onClick={handleDeleteClick}
                        >
                          Delete
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </form>
          {comment.isReplyOpen && (
            <div className="p-4 mt-2">
              <h4 className="text-primary-700 relative z-20 mb-2 mr-10 text-sm font-bold tracking-wide uppercase">
                Reply to {comment?.createdByUser?.displayName}
              </h4>
              <form onSubmit={replyFormik.handleSubmit}>
                <div className="mb-4 commentEditor">
                  <RichTextEditor
                    ref={replyEditorRef}
                    value={replyText}
                    setValue={handleReplyChange}
                    maxLength={10000}
                    disabled={saving}
                    error={
                      replyFormik.touched.replyText &&
                      replyFormik.errors.replyText
                        ? replyFormik.errors.replyText
                        : undefined
                    }
                    touched={replyFormik.touched.replyText}
                    placeholder="Type your reply here"
                  />
                </div>
                <div className="flex justify-start">
                  <Button
                    type="button"
                    outline={true}
                    disabled={saving}
                    onClick={handleCancelReply}
                    className="py-2 tablet:px-12 px-6"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="py-2 tablet:px-12 px-6 ml-2"
                    disabled={saving}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          )}
          {comment.comments && !!comment.comments.length && (
            <div className="mt-4 ml-5">
              {comment.comments.map((nestedComment) => (
                <Comment
                  key={nestedComment.id}
                  comment={nestedComment}
                  onReply={onReply}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  type={type}
                />
              ))}
            </div>
          )}
          {isConfirmationModalOpen && (
            <ConfirmationModalWrapper
              isOpen={isConfirmationModalOpen}
              title="Confirm Deletion"
              message="Are you sure you want to delete this comment?"
              onConfirm={handleConfirmDelete}
              onClose={() => setIsConfirmationModalOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};
