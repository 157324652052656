import { Furniturev2 } from './paper-furniture';
import { RenderHTML } from '../../components/paper/render-html';
import { useAppDispatch, useAppSelector } from '../../state';
import {
  selectComments,
  selectCommentsLoading
} from '../../state/selectors/commentsSelector';
import { Loading } from '../../components/core';
import { useCommentsPaper } from '../../components/discussion/hooks/useCommentsPaper';
import { Discussion } from '../../components/discussion';
import { texts } from '../../text';
import { AccordionItem } from '../../components/accordion';
import { useEffect, useState } from 'react';
import { AccordionItemStickyCitesCitedBy } from '../../components/accordion/accordionItemStickyCitesCitedBy';
import { CitesCitedByTabs } from './citesCitedBy/citesCitedByTabs';
import { getPaperReferenceCitationById } from '../../state/actions/paperActions';
import {
  selectPaperCitations,
  selectPaperReferences
} from '../../state/selectors/paperSelector';
import { analytics } from '../../analytics';

export const PaperPageContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { handleReply, handleDelete, handleEdit } = useCommentsPaper();

  const paper = useAppSelector((state) => state.paper.paper);
  const comments = useAppSelector(selectComments)?.comments;
  const loading = useAppSelector(selectCommentsLoading);
  const [isExpandedAbstract, setIsExpandedAbstract] = useState<boolean>(false);
  const [isExpandedCitations, setIsExpandedCitations] =
    useState<boolean>(false);
  const [disabledCitations, setDisabledCitations] = useState<boolean>(false);
  const doi = paper?.doi;
  const doiLink = `https://doi.org/${doi}`;
  const references = useAppSelector(selectPaperReferences);
  const citations = useAppSelector(selectPaperCitations);

  useEffect(() => {
    if (paper && paper.id) {
      dispatch(getPaperReferenceCitationById(Number(paper.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paper?.id]);

  useEffect(() => {
    if (!paper?.abstract) {
      setIsExpandedAbstract(false);
    } else {
      setIsExpandedAbstract(true);
    }
  }, [paper]);

  const handleToggleAbstract = () => {
    setIsExpandedAbstract((prev: any) => !prev);
    analytics.events.expandAbstract();
  };

  useEffect(() => {
    if (
      (!references || references.length === 0) &&
      (!citations || citations.length === 0)
    ) {
      setDisabledCitations(true);
      setIsExpandedCitations(false);
    } else {
      setDisabledCitations(false);
    }
  }, [references, citations]);

  const handleToggleCitations = () => {
    if (!disabledCitations) {
      setIsExpandedCitations((prev) => !prev);
    }
  };

  return (
    <div className="">
      <div className="paperBorder"></div>
      <main className="pt-8 pb-6 tablet:px-12 px-6">
        {paper && paper.title && paper.id && <Furniturev2 doiLink={doiLink} />}
        <div className="withStyles block mt-6">
          <div className=" tablet:order-first order-last page-content accordionPapers">
            <div className="mb-10">
              <AccordionItem
                title="Abstract"
                isExpanded={isExpandedAbstract}
                onToggle={handleToggleAbstract}
              >
                {paper?.abstract ? (
                  <RenderHTML htmlContent={paper.abstract} />
                ) : (
                  <RenderHTML
                    htmlContent={texts.papersWithoutAbstract}
                    className="text-gray-600"
                  />
                )}
              </AccordionItem>
            </div>
          </div>
          <div className="mb-0  sticky-accordion-container accordionPapers ">
            <AccordionItemStickyCitesCitedBy
              title="Cites & Cited By"
              isExpanded={isExpandedCitations}
              onToggle={handleToggleCitations}
              disabled={disabledCitations}
              tooltipText={
                disabledCitations
                  ? 'Unfortunately we don’t have information about the paper citations.'
                  : undefined
              }
              isCitesCitedBy={true}
            >
              <CitesCitedByTabs references={references} citations={citations} />
            </AccordionItemStickyCitesCitedBy>
          </div>
        </div>
      </main>
      {loading ? (
        <Loading />
      ) : (
        <Discussion
          type="paper"
          comments={comments || null}
          onReply={handleReply}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};
