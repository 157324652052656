import { FC } from 'react';
import { usePapersDiscussionsTabs } from './hooks/usePapersDiscussionsTabs';
import { formatCount } from './hooks/usePaperCount';
import { Tooltip } from '../core/tooltip';

interface PapersDiscussionsTabsProps {
  hidePapersTab?: boolean;
  contentUrlKey: string;
  type: 'topic' | 'community';
  numDiscussions?: number;
  numPapers?: number;
}

export const PapersDiscussionsTabs: FC<PapersDiscussionsTabsProps> = ({
  hidePapersTab,
  contentUrlKey,
  type,
  numDiscussions,
  numPapers
}) => {
  const { handlePapersClick, handleDiscussionsClick, radioState } =
    usePapersDiscussionsTabs({
      contentUrlKey,
      hidePapersTab
    });

  const papersCount = numPapers ? formatCount(numPapers) : '';
  const discussionCount = numDiscussions ? formatCount(numDiscussions) : '';

  return (
    <main className="flex flex-col justify-start w-full">
      <div className="tabs-row flex justify-start px-4">
        <div className="tabs max-w-3xl w-full m-auto">
          {!hidePapersTab && (
            <button
              className={`tab-button py-4 ${radioState === 'papers' ? 'active' : ''}`}
              onClick={handlePapersClick}
            >
              <span className="papers-label">
                <Tooltip
                  className="tabsTooltip"
                  text={`${numPapers} papers in the ${type}`}
                >
                  {'Papers '}
                  {numPapers !== 0 && (
                    <>
                      <span className="papers-count">({papersCount})</span>
                    </>
                  )}
                </Tooltip>
              </span>
            </button>
          )}
          <button
            className={`tab-button py-4 ${radioState === 'discussions' ? 'active' : ''}`}
            onClick={handleDiscussionsClick}
          >
            <span className="discussions-label">
              {numDiscussions && numDiscussions > 0 ? (
                <Tooltip
                  className="tabsTooltip"
                  text={`${numDiscussions} ${
                    numDiscussions === 1 ? 'discussion' : 'discussions'
                  } in the community`}
                >
                  {'Discussions '}
                  {numDiscussions !== 0 && (
                    <>
                      <span className="discussions-count">
                        ({discussionCount})
                      </span>
                    </>
                  )}
                </Tooltip>
              ) : (
                'Discussions'
              )}
            </span>
          </button>
        </div>
      </div>
    </main>
  );
};
