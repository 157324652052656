import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const VoteIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={classNames('block', className)}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.667 1.333A1.333 1.333 0 0 0 1.333 2.667v8A1.333 1.333 0 0 0 2.667 12h8a1.333 1.333 0 0 0 1.333-1.333v-8A1.333 1.333 0 0 0 10.667 1.333h-8ZM2.667 0h8c.707 0 1.385.281 1.886.781.5.501.781 1.18.781 1.886v8c0 .707-.281 1.385-.781 1.886-.501.5-1.179.781-1.886.781h-8c-.707 0-1.385-.281-1.886-.781-.5-.501-.781-1.179-.781-1.886v-8c0-.707.281-1.385.781-1.886C1.281.28 1.96 0 2.667 0Z"
        fill="#ED8936"
      />
      <path
        d="M7.333 5.61v3.724c0 .177-.07.347-.195.472a.667.667 0 0 1-.942 0A.667.667 0 0 1 6 9.333V5.61L4.31 7.3a.667.667 0 0 1-.953-.94l2.829-2.829a.667.667 0 0 1 .942 0l2.829 2.829a.667.667 0 0 1-.953.94L7.333 5.61Z"
        fill="#ED8936"
      />
    </svg>
  );
};
