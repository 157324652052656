import { FC } from 'react';
import { usePostForm } from '../hooks/usePostForm';
import { UpdatePostPage } from './updatePostPage';
import { useGetPostUserDetails } from '../hooks/useGetPostUserDetails';
import { useParams } from 'react-router-dom';
import { useUpdatePost } from '../hooks/useUpdatePost';
import DOMPurify from 'dompurify';
import { ALLOWED_ATTR, ALLOWED_TAGS } from '../../../utils/constants';
import { analytics } from '../../../analytics';

interface Props {
  closeModal: () => void;
}

export interface Tag {
  name: string;
  urlKey: string;
}

export const UpdatePostContainer: FC<Props> = ({ closeModal }) => {
  const {
    postName,
    markdown,
    selectedTopicTags,
    selectedCommunityTags,
    errors,
    inputTopicsValue,
    inputCommunityTags,
    topicTags,
    setErrors,
    communityTags,
    onChangePostName,
    onChangeMarkdown,
    removeTopic,
    addTopic,
    removeCommunity,
    addCommunity,
    filterTopics,
    filterCommunities,
    fetchMoreTopics,
    hasMoreTopicPage,
    fetchMoreCommunities,
    hasMoreCommunityPage
  } = usePostForm();

  const { id } = useParams();
  const { isUserCreator, isUserAdmin } = useGetPostUserDetails();

  const updatePostHandler = useUpdatePost(
    () => {
      closeModal();
    },
    (error) => {
      setErrors((errors) => ({
        ...errors,
        updateError: `Error updating post: ${error.message || error.toString()}`
      }));
    }
  );

  // Function to trigger the update, to be called on form submission or button click.
  const handleFormSubmit = () => {
    const topicUrlKeys = selectedTopicTags.map((tag) => tag.urlKey);
    const communityUrlKeys = selectedCommunityTags.map(
      (community) => community.urlKey
    );
    const sanitizedMarkdown = DOMPurify.sanitize(markdown, {
      ALLOWED_TAGS: ALLOWED_TAGS,
      ALLOWED_ATTR: ALLOWED_ATTR
    });

    updatePostHandler({
      id: id,
      postName,
      markdown: sanitizedMarkdown,
      selectedTopicTags: topicUrlKeys,
      selectedCommunityTags: communityUrlKeys,
      isUserAdmin,
      isUserCreator
    });
    analytics.events.editPost(postName);
  };

  return (
    <UpdatePostPage
      initialPostName={postName}
      initialDescription={markdown}
      topicTags={topicTags}
      communityTags={communityTags}
      selectedTopicTags={selectedTopicTags}
      selectedCommunityTags={selectedCommunityTags}
      removeTopic={removeTopic}
      filterTopics={filterTopics}
      addTopic={addTopic}
      addCommunity={addCommunity}
      removeCommunity={removeCommunity}
      filterCommunities={filterCommunities}
      setPostName={onChangePostName}
      setMarkdown={onChangeMarkdown}
      handleUpdate={handleFormSubmit}
      errors={errors}
      inputTopicsValue={inputTopicsValue}
      inputCommunityTags={inputCommunityTags}
      isUserAdmin={isUserAdmin}
      isUserCreator={isUserCreator}
      fetchMoreTopics={fetchMoreTopics}
      hasMoreTopicPage={hasMoreTopicPage}
      fetchMoreCommunities={fetchMoreCommunities}
      hasMoreCommunityPage={hasMoreCommunityPage}
    />
  );
};
