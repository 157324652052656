import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ITopicSummaryPaged } from '../../types/topics.type';

export const getTopicsData = createAsyncThunk<
  ITopicSummaryPaged,
  {
    page: number;
    pageSize: number;
    sortMethod?: string;
    target?: 'default' | 'creation';
    signal?: AbortSignal;
  },
  { rejectValue: string }
>('get/getTopicsData', async (params, { rejectWithValue }) => {
  const { page, pageSize, sortMethod, signal } = params;

  const query =
    `&page=${page}&pageSize=${pageSize}` +
    (sortMethod ? `&order=${sortMethod}` : '');
  const url = `${env.apiUrl}/api/v1/topics?${query}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      signal,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      let errorMessage = '';
      if (response.status >= 400 && response.status < 500) {
        const errorData = await response.json();
        errorMessage = errorData.message || 'Failed to fetch topics';
      } else if (response.status >= 500) {
        errorMessage =
          'Sorry, currently we can’t process your request. Please try again later.';
      }
      return rejectWithValue(errorMessage);
    }

    const data: ITopicSummaryPaged = await response.json();
    return data;
  } catch (error: any) {
    if (error.name === 'AbortError') throw error;
    return rejectWithValue(
      // eslint-disable-next-line quotes
      "We couldn't process your search at the moment. We're actively working on improving the site and will iron out any issues. In the meantime, please try searching for something else."
    );
  }
});

export const getLandingTopicsData = createAsyncThunk<
  ITopicSummaryPaged,
  { page: number; pageSize: number; sortMethod?: string }
>(
  'get/getLandingTopicsData',
  async ({ page, pageSize, sortMethod }, { rejectWithValue }) => {
    const query =
      `&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/topics?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }

      const data: ITopicSummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch topics');
    }
  }
);

export const searchTopics = createAsyncThunk<
  ITopicSummaryPaged,
  {
    term: string;
    page: number;
    pageSize: number;
    target?: 'default' | 'creation';
    abortController?: AbortController;
  },
  { rejectValue: string }
>(
  'search/searchTopics',
  async ({ term, page, pageSize, abortController }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/topics/search?q=${encodeURIComponent(term)}&pageNum=${page}&pageSize=${pageSize}`;
    const signal = abortController?.signal;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (response.status === 404 || response.status === 500) {
        return rejectWithValue('No results found for your search query');
      }

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || 'Failed to fetch topics');
      }

      const data: ITopicSummaryPaged = await response.json();
      return data;
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return rejectWithValue('Request was aborted');
      }
      return rejectWithValue(error.message || 'Failed to fetch topics');
    }
  }
);
