import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IPaper,
  IPaperCitations,
  IPaperReference
} from '../../types/paper.type';
import {
  addVoteToPaper,
  getPaperById,
  getPaperBySlug,
  getPaperReferenceCitationById,
  removeVoteFromPaper
} from '../actions/paperActions';
import { postCommentPaper } from '../actions/commentsActions';
import { ICommentsResponse } from '../../types/comment.type';

interface PaperState {
  paper: IPaper | null;
  references?: IPaperReference[] | null;
  citations?: IPaperCitations[] | null;
  loading: boolean;
  error: string | null;
  citationLoading: boolean;
  citationError: string | null;
}

const initialState: PaperState = {
  paper: null,
  references: null,
  citations: null,
  loading: false,
  error: null,
  citationLoading: false,
  citationError: null
};

const paperSlice = createSlice({
  name: 'paper',
  initialState,
  reducers: {
    clearPaper: (state) => {
      state.paper = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPaperById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getPaperById.fulfilled,
      (state, action: PayloadAction<IPaper>) => {
        state.paper = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getPaperById.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to fetch paper';
      state.loading = false;
    });

    builder.addCase(getPaperReferenceCitationById.pending, (state) => {
      state.citationLoading = true;
      state.citationError = null;
      state.references = null;
      state.citations = null;
    });
    builder.addCase(
      getPaperReferenceCitationById.fulfilled,
      (state, action: PayloadAction<IPaper>) => {
        state.references = action.payload.references;
        state.citations = action.payload.citations;
        state.citationLoading = false;
      }
    );
    builder.addCase(getPaperReferenceCitationById.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to fetch paper';
      state.citationLoading = false;
    });

    builder.addCase(getPaperBySlug.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getPaperBySlug.fulfilled,
      (state, action: PayloadAction<IPaper>) => {
        state.paper = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getPaperBySlug.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to fetch paper by slug';
      state.loading = false;
    });
    builder.addCase(
      postCommentPaper.fulfilled,
      (state, action: PayloadAction<ICommentsResponse>) => {
        if (state.paper) {
          state.paper.discussionId = action.payload.discussionId;
        }
      }
    );
    builder.addCase(
      addVoteToPaper.fulfilled,
      (state, action: PayloadAction<{ totalScore: number }>) => {
        if (state.paper && state.paper.votes) {
          state.paper.votes.totalScore = action.payload.totalScore;
          state.paper.votes.alreadyVoted = true;
        }
      }
    );
    builder.addCase(addVoteToPaper.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to vote on paper';
    });

    builder.addCase(
      removeVoteFromPaper.fulfilled,
      (state, action: PayloadAction<{ totalScore: number }>) => {
        if (state.paper && state.paper.votes) {
          state.paper.votes.totalScore = action.payload.totalScore;
          state.paper.votes.alreadyVoted = false;
        }
      }
    );
    builder.addCase(removeVoteFromPaper.rejected, (state, action) => {
      state.error = action.error.message || 'Failed to remove vote';
    });
  }
});

export const { clearPaper } = paperSlice.actions;

export const paperReducer = paperSlice.reducer;
