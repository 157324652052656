import { SharePost } from '../../svgs/SharePost';
import { useAppDispatch } from '../../state';
import { openModal } from '../../state/actions/modalsActions';

interface ShareButtonProps {
  url: string;
  textBig?: boolean;
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  url,
  textBig
}: ShareButtonProps) => {
  const dispatch = useAppDispatch();
  const handleOpenModal = (url: string, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(openModal('share', url));
  };

  return (
    <button
      className="flex items-center"
      onClick={(e) => handleOpenModal(url, e)}
    >
      <SharePost className="flex-shrink-0" />
      <span className={`${textBig ? 'text-sm' : 'text-half'} ml-1`}>Share</span>
    </button>
  );
};
