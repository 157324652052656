import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const VotedIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={classNames('block votedIcon', className)}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8 0H4.2C1.88 0 0 1.88 0 4.2V9.8C0 12.12 1.88 14 4.2 14H9.8C12.12 14 14 12.12 14 9.8V4.2C14 1.88 12.12 0 9.8 0ZM10.08 6.08C9.933 6.227 9.693 6.227 9.546 6.08L7 3.534V10.6C7 10.84 6.813 11.027 6.573 11.027C6.333 11.027 6.147 10.84 6.147 10.6V3.534L3.6 6.08C3.453 6.227 3.213 6.227 3.066 6.08C2.993 6.007 2.96 5.92 2.96 5.833C2.96 5.747 2.993 5.66 3.066 5.586L6.147 2.506C6.22 2.433 6.307 2.4 6.393 2.4C6.48 2.4 6.567 2.433 6.64 2.506L10.08 5.586C10.227 5.733 10.227 5.973 10.08 6.08Z"
        fill="#EE9844"
      />
    </svg>
  );
};
